import {FC, useEffect, useState, useContext, useReducer, createContext, useCallback} from 'react'
import {WithChildren} from '../../../_metronic/helpers'

type PageModel = {
  page: string
  size: number
  index: number
  filter: string
  status: boolean
}

const obj = {
  page: '',
  size: 0,
  index: 0,
  filter: '',
  status: false,
}

type HistoryPageProps = {
  currentPage: PageModel | undefined
  setCurrentPage: (page: PageModel | undefined) => void
  currentPages: PageModel | undefined
}

const initHistoryPagePropsState = {
  currentPage: undefined,
  setCurrentPage: () => {},
  currentPages: undefined,
}

const HistoryPageContext = createContext<HistoryPageProps>(initHistoryPagePropsState)

const useCurrentPage = () => {
  return useContext(HistoryPageContext)
}

const HistoryPageProvider: FC<WithChildren> = ({children}) => {
  const [currentPage, setCurrentPage] = useState<PageModel | undefined>(obj)
  let currentPages: any = {
    page: '',
    size: 0,
    index: 0,
    filter: '',
    status: false,
  }

  const UpdateCurrentPage = useCallback(
    (updatedPage: PageModel) => {
      setCurrentPage(updatedPage)
    },
    [setCurrentPage]
  )

  useEffect(() => {}, [currentPage])

  return (
    <HistoryPageContext.Provider value={{currentPage, setCurrentPage, currentPages}}>
      {children}
    </HistoryPageContext.Provider>
  )
}

export {HistoryPageProvider, useCurrentPage}
