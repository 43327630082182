import {useState, useRef} from 'react'
import {CSVLink} from 'react-csv'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileExport} from '@fortawesome/free-solid-svg-icons'
import {ExportUser} from '../../../modules/api/requests/ExportDataRequest'

const UserExportData = () => {
  const [data, setData] = useState<any>([])
  const csvLink: any = useRef()
  const name = 'users'

  const headers = [
    {label: 'ID', key: 'index'},
    {label: 'EMID', key: 'emid'},
    {label: 'Firstname', key: 'firstnameEn'},
    {label: 'LastnameEn', key: 'lastnameEn'},
    {label: 'NicknameEn', key: 'nicknameEn'},
    {label: 'Email', key: 'email'},
    {label: 'Status', key: 'status'},
    {label: 'Joined Day', key: 'created_at'},
    {label: 'role', key: 'role'},
    {label: 'Last Login', key: 'last_login'},
    {label: 'Dept Name', key: 'dept_name'},
    {label: 'Created By', key: 'created_by'},
    {label: 'Created At', key: 'created_at'},
    {label: 'Updated By', key: 'updated_by'},
    {label: 'Updated At', key: 'updated_at'},
  ]

  const getData = async () => {
    await ExportUser()
      .then((res) => {
        let rawdata = res.data.data
        const csvData = rawdata.map((row: any) => {
          return {
            index: `=""${row.index || '-'}""`,
            emid: `=""${row.emid || '-'}""`,
            firstnameEn: `=""${row.firstnameEn || '-'}""`,
            lastnameEn: `=""${row.lastnameEn || '-'}""`,
            nicknameEn: `=""${row.nicknameEn || '-'}""`,
            email: `=""${row.email || '-'}""`,
            status: `=""${row.status || '-'}""`,
            role: `=""${row.role || '-'}""`,
            last_login: `=""${row.last_login || '-'}""`,
            dept_name: `=""${row.dept_name || '-'}""`,
            created_by: `=""${row.created_by || '-'}""`,
            created_at: `=""${row.created_at || '-'}""`,
            updated_by: `=""${row.updated_by || '-'}""`,
            updated_at: `=""${row.updated_at || '-'}""`,
          }
        })
        setData(csvData)
        setTimeout(() => {
          csvLink.current.link.click()
        }, 200)
      })
      .catch((err) => {
        setData([])
      })
  }

  return (
    <>
      <button
        className='btn btn-light-success'
        onClick={() => {
          getData()
        }}
      >
        <FontAwesomeIcon icon={faFileExport} className='fa-fw me-1' />
        Export
      </button>
      <CSVLink
        data={data}
        filename={name || 'data.csv'}
        headers={headers}
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </>
  )
}

export {UserExportData}
