import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'

const ButtonUrl = ({url}: any) => {
  return (
    <>
      {url != '' ? (
        <Link to={url} className='btn btn-primary shadow-sm'>
          <FontAwesomeIcon icon={faPlus} className='fa-fw' />
          Create
        </Link>
      ) : (
        ''
      )}
    </>
  )
}

export {ButtonUrl}
