import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {LogWrapper} from '../pages/logs/Logs'
import * as PATH from '../modules/path/Path'
import {useAuth} from '../modules/auth'

// master setting
import DeptIndex from '../pages/master-setting/departments/index'
import DeptCreate from '../pages/master-setting/departments/create'
import DeptEdit from '../pages/master-setting/departments/edit'
import DCIndex from '../pages/master-setting/document-concerned/index'
import DCCreate from '../pages/master-setting/document-concerned/create'
import DCEdit from '../pages/master-setting/document-concerned/edit'
import UnitIndex from '../pages/master-setting/units/index'
import UnitCreate from '../pages/master-setting/units/create'
import UnitEdit from '../pages/master-setting/units/edit'
import ItemGroupIndex from '../pages/master-setting/items/group/index'
import ItemGroupCreate from '../pages/master-setting/items/group/create'
import ItemGroupEdit from '../pages/master-setting/items/group/edit'
import ItemIndex from '../pages/master-setting/items/item/index'
import ItemCreate from '../pages/master-setting/items/item/create'
import ItemEdit from '../pages/master-setting/items/item/edit'
import BranchIndex from '../pages/master-setting/branches/index'
import BranchCreate from '../pages/master-setting/branches/create'
import BranchEdit from '../pages/master-setting/branches/edit'
import SGIndex from '../pages/master-setting/suppliers/group/index'
import SGCreate from '../pages/master-setting/suppliers/group/create'
import SGEdit from '../pages/master-setting/suppliers/group/edit'
import SUPIndex from '../pages/master-setting/suppliers/supplier/index'
import SUPCreate from '../pages/master-setting/suppliers/supplier/create'
import SUPEdit from '../pages/master-setting/suppliers/supplier/edit'

// user and role
import UserIndex from '../pages/master-setting/users/index'
import Role from '../pages/master-setting/roles'
import RoleCreate from '../pages/master-setting/roles/create'
import RoleEdit from '../pages/master-setting/roles/edit'

// quotation
import QSIndex from '../pages/quotations/Report'
import QSCreate from '../pages/quotations/Requisition'
import QSPandingList from '../pages/quotations/PandingList'
import QSVerificationList from '../pages/quotations/VerificationList'
import QSCancel from '../pages/quotations/CancelList'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  let role: any = currentUser?.roles || null

  const RoleData = (role: any) => {
    let data: any = []
    if (role == null || typeof role === 'undefined' || role == '') {
      data = []
    } else {
      data = role.permissions
    }
    return data
  }

  const RoleIdData = (role: any) => {
    let data: any = null
    if (role == null || typeof role === 'undefined' || role == '') {
      data = null
    } else {
      data = role.id
    }
    return data
  }

  let permissions: any = RoleData(role)
  let role_id: any = RoleIdData(role)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={PATH.HOME} />} />
        {/* Pages */}
        <Route path='home' element={<DashboardWrapper />} />

        {role != null || role_id == 1 ? (
          <>
            {/* department */}
            {permissions.includes('department') || role_id == 1 ? (
              <>
                <Route path={PATH.DEPT_LIST} element={<DeptIndex />} />
                <Route path={PATH.DEPT_CREATE} element={<DeptCreate />} />
                <Route path={PATH.DEPT_EDIT + ':id'} element={<DeptEdit />} />
              </>
            ) : (
              ''
            )}

            {/* user */}
            {permissions.includes('user_management') || role_id == 1 ? (
              <>
                <Route path={PATH.USER_LIST} element={<UserIndex />} />
              </>
            ) : (
              ''
            )}

            {/* role */}
            {permissions.includes('role') || role_id == 1 ? (
              <>
                <Route path={PATH.ROLE} element={<Role />} />
                <Route path={PATH.ROLE_CREATE} element={<RoleCreate />} />
                <Route path={PATH.ROLE_EDIT + ':id'} element={<RoleEdit />} />
              </>
            ) : (
              ''
            )}

            {/* document concerned */}
            {permissions.includes('document_concerned') || role_id == 1 ? (
              <>
                <Route path={PATH.DC_LIST} element={<DCIndex />} />
                <Route path={PATH.DC_CREATE} element={<DCCreate />} />
                <Route path={PATH.DC_EDIT + ':id'} element={<DCEdit />} />
              </>
            ) : (
              ''
            )}

            {/* unit */}
            {permissions.includes('unit') || role_id == 1 ? (
              <>
                <Route path={PATH.UNIT_LIST} element={<UnitIndex />} />
                <Route path={PATH.UNIT_CREATE} element={<UnitCreate />} />
                <Route path={PATH.UNIT_EDIT + ':id'} element={<UnitEdit />} />
              </>
            ) : (
              ''
            )}

            {/* item group */}
            {permissions.includes('item_group') || role_id == 1 ? (
              <>
                <Route path={PATH.IG_LIST} element={<ItemGroupIndex />} />
                <Route path={PATH.IG_CREATE} element={<ItemGroupCreate />} />
                <Route path={PATH.IG_EDIT + ':id'} element={<ItemGroupEdit />} />
              </>
            ) : (
              ''
            )}

            {/* item */}
            {permissions.includes('item') || role_id == 1 ? (
              <>
                <Route path={PATH.ITEM_LIST} element={<ItemIndex />} />
                <Route path={PATH.ITEM_CREATE} element={<ItemCreate />} />
                <Route path={PATH.ITEM_EDIT + ':id'} element={<ItemEdit />} />
              </>
            ) : (
              ''
            )}

            {/* branch */}
            {permissions.includes('branch') || role_id == 1 ? (
              <>
                <Route path={PATH.BRANCH_LIST} element={<BranchIndex />} />
                <Route path={PATH.BRANCH_CREATE} element={<BranchCreate />} />
                <Route path={PATH.BRANCH_EDIT + ':id'} element={<BranchEdit />} />
              </>
            ) : (
              ''
            )}

            {/* Supplier Group */}
            {permissions.includes('supplier_group') || role_id == 1 ? (
              <>
                <Route path={PATH.SG_LIST} element={<SGIndex />} />
                <Route path={PATH.SG_CREATE} element={<SGCreate />} />
                <Route path={PATH.SG_EDIT + ':id'} element={<SGEdit />} />
              </>
            ) : (
              ''
            )}

            {/* Supplier */}
            {permissions.includes('supplier') || role_id == 1 ? (
              <>
                <Route path={PATH.SUP_LIST} element={<SUPIndex />} />
                <Route path={PATH.SUP_CREATE} element={<SUPCreate />} />
                <Route path={PATH.SUP_EDIT + ':id'} element={<SUPEdit />} />
              </>
            ) : (
              ''
            )}

            {/* Log */}
            {permissions.includes('log') || role_id == 1 ? (
              <Route path={PATH.LOG} element={<LogWrapper />} />
            ) : (
              ''
            )}

            {/* quotation */}
            {permissions.includes('qs_report') || role_id == 1 ? (
              <Route path={PATH.QS_LIST} element={<QSIndex />} />
            ) : (
              ''
            )}
            {permissions.includes('qs_create') || role_id == 1 ? (
              <Route path={PATH.QS_CREATE} element={<QSCreate />} />
            ) : (
              ''
            )}
            {permissions.includes('qs_pending') || role_id == 1 ? (
              <Route path={PATH.QS_PENDING} element={<QSPandingList />} />
            ) : (
              ''
            )}
            {permissions.includes('qs_verify') || role_id == 1 ? (
              <Route path={PATH.QS_VERIFY} element={<QSVerificationList />} />
            ) : (
              ''
            )}
            {permissions.includes('qs_cancel') || role_id == 1 ? (
              <Route path={PATH.QS_CANCEL} element={<QSCancel />} />
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            {/* quotation */}
            <Route path={PATH.QS_LIST} element={<QSIndex />} />
            <Route path={PATH.QS_CREATE} element={<QSCreate />} />
          </>
        )}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
