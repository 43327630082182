import {useEffect, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {DeptFind, DeptUpdate} from '../../../modules/api/requests/DeptRequest'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faXmark} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import * as PATH from '../../../modules/path/Path'
import {useCurrentPage} from '../HistoryPage'

const DeptForm = () => {
  const department = {
    id: 0,
    dept_name: '',
    dept_no: '',
    dept_alias_name: '',
    header_dept_pr_no: '',
    factory_no: '',
    acc_remark: '',
    groupleader_increase: 0,
    sectionhead_increase: 0,
    senior_increase: 0,
    depthead_increase: 0,
    active: 0,
  }
  const [dept, setDept] = useState(department)
  const [checked, setChecked] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const id: string = params.id ?? ''
  const {currentPage, setCurrentPage, currentPages} = useCurrentPage()

  const getDept = async () => {
    const res: any = await DeptFind(id)
    if (res) {
      if (res.data.status == true) {
        setDept(res.data.data)
        if (res.data.data.active === 1) {
          setChecked(true)
        } else {
          setChecked(false)
        }
      }
    }
  }

  const setActive = async (ac: any) => {
    if (ac == true) {
      formik.setFieldValue('active', 1)
    } else if (ac == false) {
      formik.setFieldValue('active', 0)
    }
    setChecked(!checked)
  }

  const getPage = () => {
    if (currentPages) {
      currentPages.page = 'departments'
      currentPages.status = false
    }
  }

  useEffect(() => {
    getPage()
    getDept()
  }, [])

  const comeback = () => {
    if (formik.values.dept_name != dept.dept_name || formik.values.active != dept.active) {
      Swal.fire({
        title: 'Are you confirm to cancel?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(PATH.DEPT_LIST)
        }
      })
    } else {
      navigate(PATH.DEPT_LIST)
    }
  }

  const numberOnly = (event: any) => {
    let input = event.target.value
    event.target.value = input.replace(/[^0-9]/g, '')
  }

  const IsEmty = () => {
    if (
      !formik.values.dept_name ||
      !formik.values.dept_no ||
      !formik.values.dept_alias_name ||
      !formik.values.header_dept_pr_no ||
      !formik.values.factory_no ||
      !formik.values.acc_remark ||
      !formik.values.groupleader_increase ||
      !formik.values.sectionhead_increase ||
      !formik.values.senior_increase ||
      !formik.values.depthead_increase
    ) {
      Swal.fire({
        title: 'Please complete the information.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: dept.id,
      dept_name: dept.dept_name,
      dept_no: dept.dept_no,
      dept_alias_name: dept.dept_alias_name,
      header_dept_pr_no: dept.header_dept_pr_no,
      factory_no: dept.factory_no,
      acc_remark: dept.acc_remark,
      groupleader_increase: dept.groupleader_increase,
      sectionhead_increase: dept.sectionhead_increase,
      senior_increase: dept.senior_increase,
      depthead_increase: dept.depthead_increase,
      active: dept.active,
    },
    validationSchema: Yup.object({
      dept_name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('dept name is required'),
      dept_no: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('dept no is required'),
      dept_alias_name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('dept alias name is required'),
      header_dept_pr_no: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('header dept pr no is required'),
      factory_no: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('factory no pr no is required'),
      acc_remark: Yup.string().required('acc remark pr no is required'),
      groupleader_increase: Yup.number()
        .min(0, 'groupleader increase is required')
        .required('groupleader increase is required'),
      sectionhead_increase: Yup.number()
        .min(0, 'sectionhead increase is required')
        .required('sectionhead increase is required'),
      senior_increase: Yup.number()
        .min(0, 'senior increase is required')
        .required('senior increase is required'),
      depthead_increase: Yup.number()
        .min(0, 'depthead increaseis required')
        .required('depthead increase is required'),
    }),
    onSubmit: async (values) => {
      Swal.fire({
        title: 'Are you confirm to update?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await DeptUpdate(
              values.id,
              values.dept_name,
              values.dept_no,
              values.dept_alias_name,
              values.header_dept_pr_no,
              values.factory_no,
              values.acc_remark,
              values.groupleader_increase,
              values.sectionhead_increase,
              values.senior_increase,
              values.depthead_increase,
              values.active
            )
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful updated!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    navigate(PATH.DEPT_LIST)
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to save',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Department - Edit</h3>
            </div>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-danger shadow-sm me-2' onClick={comeback}>
                <FontAwesomeIcon icon={faXmark} className='fa-fw' />
                Cancel
              </button>
              <button type='submit' className='btn btn-primary shadow-sm' onClick={IsEmty}>
                <FontAwesomeIcon icon={faSave} className='fa-fw' />
                Save
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-12'>
                {/* dept name */}
                <div className='form-group mb-4'>
                  <label htmlFor='dept_name' className='fw-bold required mb-2'>
                    Dept Name
                  </label>
                  <input
                    type='text'
                    id='dept_name'
                    name='dept_name'
                    className={'form-control form-control-sm'}
                    value={formik.values.dept_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.dept_name && formik.errors.dept_name ? (
                    <div className='text-danger'>{formik.errors.dept_name}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-3'>
                {/* dept no */}
                <div className='form-group mb-4'>
                  <label htmlFor='dept_no' className='fw-bold required mb-2'>
                    Dept no
                  </label>
                  <input
                    type='text'
                    id='dept_no'
                    name='dept_no'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dept_no}
                  />
                  {formik.touched.dept_no && formik.errors.dept_no ? (
                    <div className='text-danger'>{formik.errors.dept_no}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-3'>
                {/* dept alias name */}
                <div className='form-group mb-4'>
                  <label htmlFor='dept_alias_name' className='fw-bold required mb-2'>
                    Dept Alias Name
                  </label>
                  <input
                    type='text'
                    id='dept_alias_name'
                    name='dept_alias_name'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dept_alias_name}
                  />
                  {formik.touched.dept_alias_name && formik.errors.dept_alias_name ? (
                    <div className='text-danger'>{formik.errors.dept_alias_name}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-3'>
                {/* header dept pr no */}
                <div className='form-group mb-4'>
                  <label htmlFor='header_dept_pr_no' className='fw-bold required mb-2'>
                    Header Dept pr no
                  </label>
                  <input
                    type='text'
                    id='header_dept_pr_no'
                    name='header_dept_pr_no'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.header_dept_pr_no}
                  />
                  {formik.touched.header_dept_pr_no && formik.errors.header_dept_pr_no ? (
                    <div className='text-danger'>{formik.errors.header_dept_pr_no}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-3'>
                {/* factory no */}
                <div className='form-group mb-4'>
                  <label htmlFor='factory_no' className='fw-bold required mb-2'>
                    Factory no
                  </label>
                  <input
                    type='text'
                    id='factory_no'
                    name='factory_no'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.factory_no}
                  />
                  {formik.touched.factory_no && formik.errors.factory_no ? (
                    <div className='text-danger'>{formik.errors.factory_no}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-3'>
                {/* groupleader increase*/}
                <div className='form-group mb-4'>
                  <label htmlFor='groupleader_increase' className='fw-bold required mb-2'>
                    Groupleader Increase
                  </label>
                  <input
                    type='text'
                    id='groupleader_increase'
                    name='groupleader_increase'
                    className={'form-control form-control-sm'}
                    onInput={(e) => {
                      numberOnly(e)
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.groupleader_increase}
                  />
                  {formik.touched.groupleader_increase && formik.errors.groupleader_increase ? (
                    <div className='text-danger'>{formik.errors.groupleader_increase}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-3'>
                {/* sectionhead increase*/}
                <div className='form-group mb-4'>
                  <label htmlFor='sectionhead_increase' className='fw-bold required mb-2'>
                    Sectionhead Increase
                  </label>
                  <input
                    type='text'
                    id='sectionhead_increase'
                    name='sectionhead_increase'
                    className={'form-control form-control-sm'}
                    onInput={(e) => {
                      numberOnly(e)
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sectionhead_increase}
                  />
                  {formik.touched.sectionhead_increase && formik.errors.sectionhead_increase ? (
                    <div className='text-danger'>{formik.errors.sectionhead_increase}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-3'>
                {/* Senior Increase*/}
                <div className='form-group mb-4'>
                  <label htmlFor='senior_increase' className='fw-bold required mb-2'>
                    Senior Increase
                  </label>
                  <input
                    type='text'
                    id='senior_increase'
                    name='senior_increase'
                    className={'form-control form-control-sm'}
                    onInput={(e) => {
                      numberOnly(e)
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.senior_increase}
                  />
                  {formik.touched.senior_increase && formik.errors.senior_increase ? (
                    <div className='text-danger'>{formik.errors.senior_increase}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-3'>
                {/* Deptheadc Increase */}
                <div className='form-group mb-4'>
                  <label htmlFor='depthead_increase' className='fw-bold required mb-2'>
                    Depthead Increase
                  </label>
                  <input
                    type='text'
                    id='depthead_increase'
                    name='depthead_increase'
                    className={'form-control form-control-sm'}
                    onInput={(e) => {
                      numberOnly(e)
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.depthead_increase}
                  />
                  {formik.touched.depthead_increase && formik.errors.depthead_increase ? (
                    <div className='text-danger'>{formik.errors.depthead_increase}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                {/* acc remark */}
                <div className='form-group mb-4'>
                  <label htmlFor='acc_remark' className='fw-bold required mb-2'>
                    Acc Remark
                  </label>
                  <textarea
                    id='acc_remark'
                    name='acc_remark'
                    className={'form-control form-control-sm'}
                    value={formik.values.acc_remark}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.acc_remark && formik.errors.acc_remark ? (
                    <div className='text-danger'>{formik.errors.acc_remark}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                {/* active */}
                <div className='form-group mb-4'>
                  <label htmlFor='active' className='fw-bold  mb-2'>
                    Active
                  </label>
                  <div className='mt-2'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        checked={checked}
                        type='checkbox'
                        id='active'
                        name='active'
                        value={formik.values.active}
                        onChange={(e) => {
                          setActive(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                  {formik.touched.active && formik.errors.active ? (
                    <div className='text-danger'>{formik.errors.active}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
  {
    title: 'Department',
    path: PATH.DEPT_LIST,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const DeptEdit = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Department - Edit</PageTitle>
      <DeptForm />
    </>
  )
}

export default DeptEdit
