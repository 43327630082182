import {useTable} from 'react-table'

const QSCreateTable = ({columns, data}: any) => {
  const {getTableBodyProps, getTableProps, headerGroups, prepareRow, rows} = useTable({
    columns,
    data,
  })

  return (
    <>
      <div className='table-responsive' style={{maxHeight: '550px'}}>
        <table {...getTableProps()} className='table table-sm'>
          <thead className='bg-light-primary text-center p-1 header-table-sticky'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    <th
                      {...column.getHeaderProps([
                        {
                          className: column.headerClassName,
                        },
                      ])}
                    >
                      {column.render('Header')}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td className='align-middle' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export {QSCreateTable}
