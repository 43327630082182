import React, { useState } from 'react'
import {
  useTable, usePagination, useGlobalFilter,
  useAsyncDebounce,
  useFilters,
  useSortBy,
} from 'react-table'
import { Pagination } from './Pagination'
import clsx from 'clsx'
import { GlobalFilter } from './GlobalFilter'
import { ButtonUrl } from './ButtonUrl'
import { KTSVG } from '../../../../_metronic/helpers'
import { useCurrentPage } from '../../../pages/master-setting/HistoryPage'


const TablePagination = ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  exportData,
  url,
  type,
}) => {
  const { currentPages } = useCurrentPage()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    resetPage,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: currentPages.page == type ? currentPages.index : 0 },
    manualPagination: true,
    pageCount: controlledPageCount,
  },
    usePagination
  )

  const [filter, setFilter] = React.useState('');

  const handleFilter = (value) => {
    gotoPage(0);
    setFilter(value)
  }

  const CanPreviousPage = (index, count) => {
    if (count > 0) {
      if (index == 0) {
        return false;
      } else {
        if ((index + 1) <= count) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  const CanNextPage = (index, count) => {
    if (count > 0) {
      if (index == 0) {
        return true;
      } else {
        if ((index + 1) == count) {
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  }

  const getPage = () => {
    if (currentPages) {
      if (currentPages?.page == type) {
        if (currentPages?.status == false) {
          setPageSize(currentPages?.size);
          gotoPage(currentPages?.index);
          setFilter(currentPages?.filter);
        }
      }
    }
  }

  React.useEffect(() => {
    getPage()
    fetchData({ pageIndex, pageSize, filter });
  }, [fetchData, pageIndex, pageSize, filter])

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input id='search' type='text' data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={filter}
                onInput={(e) => {
                  handleFilter(e.target.value)
                }} />
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='me-2'>
              {exportData || ''}
            </div>
            <ButtonUrl url={url} />
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive' style={{ maxHeight: '550px' }}>

            <table {...getTableProps()} className='table table-sm table-row-bordered align-middle gy-4 gs-9 text-center'>
              <thead className='sticky border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light header-table-sticky'>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps([
                          {
                            className: column.headerClassName,
                          },
                        ])}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className='fw-semibold text-gray-600'>
                {page.length > 0 ? (
                  <>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return <td {...cell.getCellProps([
                              {
                                className: cell.column.columnClassName || '',
                              },
                            ])}>
                              {cell.render('Cell')}
                            </td>
                          })}
                        </tr>
                      )
                    })}
                    <tr>
                      {loading ? (
                        <td colSpan={columns.length}>Loading...</td>
                      ) : (
                        ''
                      )}
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          </div>
          <div className='header-table-sticky'>
            <Pagination pageOptions={pageOptions}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageCount={pageCount} />
          </div>
        </div>
      </div >
    </>
  )
}



export { TablePagination }
