import axios from 'axios'
import {RoleModel} from '../models/RoleModel'

const API_URL = process.env.REACT_APP_API_URL
export const ROLE = `${API_URL}/role`
export const ROLE_FIND = `${API_URL}/role/find`
export const ROLE_OPTION = `${API_URL}/role-option`

export function RoleList(limit: number, skip: number, search: any) {
  return axios.get<RoleModel>(ROLE + `/${limit}&${skip}&${search || null}`)
}

export function RoleStore(name: string, options: any) {
  return axios.post(ROLE, {
    name,
    options,
  })
}

export function RoleFind(id: string) {
  return axios.get(ROLE_FIND + `/${id}`)
}

export function RoleUpdate(id: number, name: string, options: any) {
  return axios.put(ROLE, {
    id,
    name,
    options,
  })
}

export function RoleOption() {
  return axios.get(ROLE_OPTION)
}
