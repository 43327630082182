import {useAuth} from '../../../../../app/modules/auth'
import {CurrentTime} from '../../../../../app/modules/widgets/func/CurrentTime'

const UserTitle = () => {
  const {currentUser}: any = useAuth()

  return (
    <div className='card user-box'>
      <div className='card-body text-center p-3'>
        <div>[DSST]</div>
        <div>
          {currentUser.emid}: {currentUser.first_name + ' ' + currentUser.last_name}
        </div>
        <div>{CurrentTime()}</div>
      </div>
    </div>
  )
}

export {UserTitle}
