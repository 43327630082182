import {useState} from 'react'

const ReadMore = (word: string) => {
  const [show, setShow] = useState(false)

  const handleShow = () => {
    if (show == true) {
      setShow(false)
    } else {
      setShow(true)
    }
  }

  if (typeof word !== 'undefined' && word !== null && typeof word === 'string') {
    const limit: number = 20
    const wordLength: number = word.length

    if (wordLength > limit) {
      const str: string = word.slice(0, limit)
      const str2: string = word.slice(limit + 1, wordLength)
      return (
        <span onClick={handleShow}>
          {str}
          {show ? str2 : '...'}
        </span>
      )
    } else {
      return word
    }
  } else {
    return '-'
  }
}

export {ReadMore}
