import axios, {AxiosRequestConfig} from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const QS = `${API_URL}/quotation`
export const QS_LIST = `${API_URL}/quotation/list`
export const QS_CREATE = `${API_URL}/quotation/create`
export const QS_UPDATE = `${API_URL}/quotation/update`
export const PIC_FILE = `${API_URL}/upload-pic`
export const DRAW_FILE = `${API_URL}/upload-draw`
export const QS_FILE = `${API_URL}/upload-quotation`
export const SUP_FILE = `${API_URL}/upload-supplier`
export const QS_HOME = `${API_URL}/quotation/home`
export const QS_HOME_BY_STATUS = `${API_URL}/quotation/home-by-status`
export const QS_COUNT_ALL = `${API_URL}/quotation/count-all`
export const QS_PENDING = `${API_URL}/quotation/pending`
export const QS_PENDING_MUL = `${API_URL}/quotation/pending/mul`
export const QS_PENDING_SIG = `${API_URL}/quotation/pending/sig`
export const QS_VERIFY = `${API_URL}/quotation/verify`
export const QS_VERIFY_FIND = `${API_URL}/quotation/verify/find`
export const QS_VERIFY_FINISHED = `${API_URL}/quotation/verify/finished`
export const QS_VERIFY_GROUP = `${API_URL}/quotation/verify/group`
export const QS_VERIFY_ADD_GROUP_LIST = `${API_URL}/quotation/verify/add/group-list`
export const QS_VERIFY_ADD_GROUP = `${API_URL}/quotation/verify/add-group`
export const QS_VERIFY_REMOVE_GROUP = `${API_URL}/quotation/verify/remove`
export const QS_VERIFY_GROUP_LIST = `${API_URL}/quotation/verify/group-list`
export const QS_NEWITEM = `${API_URL}/quotation/newitem`
export const QS_NEWSUP = `${API_URL}/quotation/newsupplier`
export const QS_GROUP_LIST = `${API_URL}/quotation/group-list`
export const QS_CANCEL_LIST = `${API_URL}/quotation/cancel-list`
export const QS_DESTROY_BY_COMMENT = `${API_URL}/quotation/delete-by-comment`

export function QSList(limit: number, skip: number, search: any, newdate: any, status: any) {
  return axios.get(QS_LIST + `/${limit}&${skip}&${search}&${newdate}&${status}`)
}

export function QSStore(data: any) {
  return axios.post(QS_CREATE, {
    data,
  })
}

export function QSUpdate(
  id: number,
  require_date?: any,
  dept_id?: any,
  for_dept_id?: any,
  urgency?: any,
  item_group_id?: any,
  item_id?: any,
  item_other?: any,
  qty?: any,
  unit_id?: any,
  supplier_id?: any,
  supplier_other?: any,
  file_url_pic?: any,
  file_url_draw?: any,
  file_url_qto?: any,
  branch_id?: any,
  status?: any,
  remark?: any,
  gp_supplier_choose_id_one?: any,
  gp_supplier_other_one?: any,
  gp_supplier_choose_id_sec?: any,
  gp_supplier_other_sec?: any,
  gp_supplier_choose_id_thr?: any,
  gp_supplier_other_thr?: any,
  gp_supplier_choose_file_one?: any,
  gp_supplier_choose_file_sec?: any,
  gp_supplier_choose_file_thr?: any,
) {
  return axios.put(QS_UPDATE, {
    id,
    require_date,
    dept_id,
    for_dept_id,
    urgency,
    item_group_id,
    item_id,
    item_other,
    qty,
    unit_id,
    supplier_id,
    supplier_other,
    file_url_pic,
    file_url_draw,
    file_url_qto,
    branch_id,
    status,
    remark,
    gp_supplier_choose_id_one,
    gp_supplier_other_one,
    gp_supplier_choose_id_sec,
    gp_supplier_other_sec,
    gp_supplier_choose_id_thr,
    gp_supplier_other_thr,
    gp_supplier_choose_file_one,
    gp_supplier_choose_file_sec,
    gp_supplier_choose_file_thr,
  })
}

export function QSDel(id: number) {
  return axios.delete(QS + `/${id}`)
}

export function UploadPIC(file: any) {
  return axios.post(PIC_FILE, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function UploadDRAW(file: any) {
  return axios.post(DRAW_FILE, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function UploadQS(file: any) {
  return axios.post(QS_FILE, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function UploadSUP(file: any) {
  return axios.post(SUP_FILE, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

interface QSHomeConfig extends AxiosRequestConfig {
  params?: {
    limit: number
    skip: number
    status: any
    dept_id?: any
    requestor_name?: any
    branch_id?: any
    request_date?: any
  }
}

export function QSHOME(config?: QSHomeConfig) {
  return axios.get(QS_HOME, config)
}

export function QSHomeByStatus(
  limit: number,
  skip: number,
  status: string,
  dept_id?: any,
  requestor_name?: any
) {
  return axios.get(QS_HOME_BY_STATUS + `/${limit}&${skip}&${status}&${dept_id}&${requestor_name}`)
}

export function QSCountAll() {
  return axios.get(QS_COUNT_ALL)
}

export function QSPending(limit: number, skip: number, search: any) {
  return axios.get(QS_PENDING + `/${limit}&${skip}&${search}`)
}

export function QSPendingUpdate(data: any) {
  return axios.put(QS_PENDING_MUL, {
    data,
  })
}

export function QSPendingApprove(
  id: number,
  status: string,
  gp_verify_id: number,
  gp_verify_comment: string
) {
  return axios.put(QS_PENDING_SIG, {
    id,
    status,
    gp_verify_id,
    gp_verify_comment,
  })
}

export function QSVerify(limit: number, skip: number, search: any, status: any, group_by: any) {
  return axios.get(QS_VERIFY + `/${limit}&${skip}&${search}&${status}&${group_by}`)
}

export function QSVerifyFind(id: number) {
  return axios.get(QS_VERIFY_FIND + `/${id}`)
}

export function QSVerifyUpdate(data: any) {
  return axios.put(QS_VERIFY, {
    data,
  })
}

export function QSVerifyFinished(data: any) {
  return axios.put(QS_VERIFY_FINISHED, {
    data,
  })
}

export function QSVerifyGroup(data: any) {
  return axios.put(QS_VERIFY_GROUP, {
    data,
  })
}

export function QSVerifyAddGroupList() {
  return axios.get(QS_VERIFY_ADD_GROUP_LIST)
}

export function QSVerifyAddGroup(id: any, job_id: number) {
  return axios.post(QS_VERIFY_ADD_GROUP, {
    id,
    job_id,
  })
}

export function QSVerifyRemoveFormGroup(data: any) {
  return axios.post(QS_VERIFY_REMOVE_GROUP, {
    data,
  })
}

export function QSVerifyGroupList() {
  return axios.get(QS_VERIFY_GROUP_LIST)
}

export function QSVerifySetGroup(data: any) {
  return axios.post(QS_VERIFY_REMOVE_GROUP, {
    data,
  })
}

export function QSNewItem(
  qsid: number,
  id: any,
  title: string,
  price: number,
  qty: number,
  item_group_id: string,
  unit_id: string,
  description: string,
  active: number
) {
  return axios.post(QS_NEWITEM, {
    qsid,
    id,
    title,
    price,
    qty,
    item_group_id,
    unit_id,
    description,
    active,
  })
}

export function QSNewSupplier(
  qsid: number,
  id: any,
  title: string,
  tel: string,
  email: string,
  fax: string,
  contact_name: string,
  address: string,
  active: number,
  bpcs_code: string,
  branch_id: number,
  supplier_group_id: number
) {
  return axios.post(QS_NEWSUP, {
    qsid,
    id,
    title,
    tel,
    email,
    fax,
    contact_name,
    address,
    active,
    bpcs_code,
    branch_id,
    supplier_group_id,
  })
}

export function QuotationGroupList(job_id: number) {
  return axios.get(QS_GROUP_LIST + `/${job_id}`)
}

interface QSCancelListConfig extends AxiosRequestConfig {
  params?: {
    limit: number
    skip: number
    search?: any
    newdate?: any
  }
}

export function QuotationCancelList(config?: QSCancelListConfig) {
  return axios.get(QS_CANCEL_LIST, config)
}

export function QuotationDestroyByComment(id: number, comment?: any) {
  return axios.put(QS_DESTROY_BY_COMMENT, {
    id,
    comment,
  })
}
