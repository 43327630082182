import axios from 'axios'
import {UnitModel} from '../models/UnitModel'

const API_URL = process.env.REACT_APP_API_URL

export const UNIT = `${API_URL}/unit`
export const UNIT_FIND = `${API_URL}/unit/find`
export const UNIT_OPTION = `${API_URL}/unit-option`

export function UnitList(limit: number, skip: number, search: any) {
  return axios.get<UnitModel>(UNIT + `/${limit}&${skip}&${search || null}`)
}

export function UnitSave(title: string, uom?: string, description?: string, active?: number) {
  return axios.post(UNIT, {
    title,
    uom,
    description,
    active,
  })
}

export function UnitFind(id: string) {
  return axios.get<UnitModel>(UNIT_FIND + `/${id}`)
}

export function UnitUpdate(
  id: number,
  title: string,
  uom?: string,
  description?: string,
  active?: number
) {
  return axios.put(UNIT, {
    id,
    title,
    uom,
    description,
    active,
  })
}

export function UnitDel(id: number) {
  return axios.delete(UNIT + `/${id}`)
}

export function UnitTitle(id: number) {
  return axios.get<UnitModel>(UNIT + `/${id}`)
}

export function UnitOption() {
  return axios.get(UNIT_OPTION)
}
