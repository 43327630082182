import {useTable, useGroupBy, useExpanded} from 'react-table'

const GroupTable = ({columns, data}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {groupBy, expanded},
  } = useTable(
    {
      columns,
      data,
    },
    useGroupBy,
    useExpanded
  )

  return (
    <>
      <div className='table-responsive'>
        <table
          {...getTableProps()}
          className='table table-sm table-row-bordered table-hover align-middle text-center'
        >
          <thead className='border-bottom border-gray-200 text-gray-600 fw-bold bg-light bg-opacity-75'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.headerClassName,
                      },
                    ])}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.isGrouped ? (
                          <>
                            {cell.render('Cell')} ({row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : (
                          cell.render('Cell')
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export {GroupTable}
