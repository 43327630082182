import {useState, useMemo, useRef, useCallback, useEffect} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {SUPList, SUPDel, SUPFind} from '../../../../modules/api/requests/SupplierRequest'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {TablePagination} from '../../../../modules/widgets/table/TablePagination'
import {activeFormat, ConvertActive} from '../../../../modules/widgets/func/ActiveFormat'
import {SupExportData} from './export'
import {SupShow} from './show'
import {ReturnNull} from '../../../../modules/widgets/func/ReturnNull'
import {ReadMore} from '../../../../modules/widgets/func/ReadMore'
import {CoverTime4} from '../../../../modules/widgets/func/CoverTime'
import * as PATH from '../../../../modules/path/Path'
import {useCurrentPage} from '../../HistoryPage'

const SUPForm = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = useRef(0)
  const [index, setIndex] = useState(0)
  const [size, setSize] = useState(0)
  const [filters, setFilters] = useState('')

  const {currentPage, setCurrentPage, currentPages} = useCurrentPage()

  const fetchAPI = async ({limit, skip, search}: any) => {
    try {
      let lt = limit || 10
      let sk = skip || 0
      let sr: any = encodeURIComponent(JSON.stringify(search || null))
      const res: any = await SUPList(lt, sk, sr)
      let data: any = res.data.data
      let count: number = res.data.count
      setData(data)
      setPageCount(Math.ceil(count / limit))
      setLoading(false)
    } catch (err) {
      console.log('Error while fecth')
    }
  }

  const fetchData = useCallback(({pageSize, pageIndex, filter}: any) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)
    setIndex(pageIndex)
    setSize(pageSize)
    setFilters(filter)
    if (fetchId == fetchIdRef.current) {
      if (currentPages) {
        let page = currentPages?.page
        let cIndex = currentPages?.index
        let cSize = currentPages?.size
        let cFilter = currentPages?.filter
        let cStatus = currentPages?.status
        if (page === 'suppliers') {
          if (cStatus == false) {
            setIndex(cIndex)
            setSize(cSize)
            setFilters(cFilter)
            currentPages.status = true
            fetchAPI({limit: cSize, skip: cSize * cIndex, search: cFilter})
          } else {
            currentPages.page = 'suppliers'
            currentPages.size = pageSize
            currentPages.index = pageIndex
            currentPages.filter = filter
            currentPages.status = true
            fetchAPI({limit: pageSize, skip: pageSize * pageIndex, search: filter})
          }
        } else {
          currentPages.page = 'suppliers'
          currentPages.size = pageSize
          currentPages.index = pageIndex
          currentPages.filter = filter
          currentPages.status = true
          fetchAPI({limit: pageSize, skip: pageSize * pageIndex, search: filter})
        }
      } else {
        fetchAPI({limit: pageSize, skip: pageSize * pageIndex, search: filter})
      }
    }
  }, [])

  const getData = () => {
    fetchAPI({limit: size || 10, skip: (size * index) || 0, search: filters || ''})
  }

  const del = async (id: number) => {
    Swal.fire({
      title: 'Are you confirm to delete?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      heightAuto: false,
      returnFocus: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await SUPDel(id)
          .then((res) => {
            if (res.data.status == true) {
              Swal.fire({
                title: 'Successful deleted!',
                icon: 'success',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500,
                returnFocus: false,
              }).then(() => {
                getData()
              })
            }
          })
          .catch((err) => {
            Swal.fire({
              title: 'Failed to delete',
              icon: 'error',
              heightAuto: false,
              returnFocus: false,
            })
          })
      }
    })
  }

  useEffect(() => {}, [size, index, filters])

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        id: 'index',
        headerClassName: 'min-w-50px',
        accessor: (_row: any, i: number) => {
          if(currentPages){
            if(currentPages.page == 'suppliers'){
              return i + 1 + currentPages.size * currentPages.index
            }
          }
          return i + 1 + size * index
        },
      },
      {
        Header: 'Title',
        id: 'title',
        headerClassName: 'min-w-250px',
        Cell: ({cell}: any) => <>{ReadMore(cell.row.original.title)}</>,
      },
      {
        Header: 'Tel',
        id: 'tel',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => <>{ReadMore(cell.row.original.tel)}</>,
      },
      {
        Header: 'Branch',
        id: 'branch',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => <>{ReadMore(cell.row.original.branch_title)}</>,
      },
      {
        Header: 'Supplier Group',
        id: 'supplier_group',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => <>{ReadMore(cell.row.original.supplier_group_title)}</>,
      },
      {
        Header: 'Active',
        id: 'actvie',
        accessor: (row: any) => ConvertActive(row.active),
        headerClassName: 'min-w-50px',
        Cell: ({cell}: any) => <span>{activeFormat(cell.row.original.active)}</span>,
      },
      {
        Header: 'Last Update',
        id: 'updated',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-center flex-column'>
            <div className='fw-bold'>{ReturnNull(cell.row.original.updated_by)}</div>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {CoverTime4(cell.row.original.updated_at)}
            </span>
          </div>
        ),
      },
      {
        Header: 'Action',
        id: 'action',
        headerClassName: 'text-end',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <Link
              to={PATH.SUP_EDIT + cell.row.original.id}
              className='btn btn-icon btn-light-primary  btn-sm me-2'
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </Link>
            <SupShow data={cell.row.original || {}} />
          </div>
        ),
      },
    ],
    []
  )

  return (
    <TablePagination
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      exportData={<SupExportData />}
      url={PATH.SUP_CREATE}
      type={'suppliers'}
    />
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const SUPIndex = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Supplier</PageTitle>
      <SUPForm />
    </>
  )
}

export default SUPIndex
