import {useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {ItemSave} from '../../../../modules/api/requests/ItemRequest'
import {UnitOption} from '../../../../modules/api/requests/UnitRequest'
import {ItemGroupOption} from '../../../../modules/api/requests/ItemGroupRequest'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faXmark} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import Select from 'react-select'
import * as PATH from '../../../../modules/path/Path'
import {customStyles} from '../../../../modules/widgets/Styles/ReactSelect'
import AsyncSelect from 'react-select/async'
import {setTimeout} from 'timers/promises'
import {useCurrentPage} from '../../HistoryPage'

const ItemFrom = () => {
  const navigate = useNavigate()
  const [groups, setGroup] = useState([])
  const [units, setUnit] = useState([])
  const [checked, setChecked] = useState(true)

  const [isClearable, setIsClearable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const {currentPage, setCurrentPage, currentPages} = useCurrentPage()

  const getGroup = async () => {
    let options: any = []
    const res: any = await ItemGroupOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setGroup(options)
    }
    console.log(options)
  }

  const getUnit = async () => {
    let options: any = []
    const res: any = await UnitOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setUnit(options)
    }
  }

  const setActive = async (ac: any) => {
    if (ac == true) {
      formik.setFieldValue('active', 1)
    } else if (ac == false) {
      formik.setFieldValue('active', 0)
    }
    setChecked(!checked)
  }

  const getPage = () => {
    if (currentPages) {
      currentPages.page = 'items'
      currentPages.status = false
    }
  }

  useEffect(() => {
    getPage()
    getGroup()
    getUnit()
  }, [])

  const comeback = () => {
    if (
      formik.values.title != '' ||
      formik.values.description != '' ||
      formik.values.price != 0 ||
      formik.values.item_group != '' ||
      formik.values.unit != '' ||
      formik.values.active != 1
    ) {
      Swal.fire({
        title: 'Are you confirm to cancel?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(PATH.ITEM_LIST)
        }
      })
    } else {
      navigate(PATH.ITEM_LIST)
    }
  }

  const numberOnly = (event: any) => {
    let input = event.target.value
    event.target.value = input
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/^0[^.]/, '0')
  }

  const IsEmty = () => {
    if (
      !formik.values.title ||
      !formik.values.price ||
      !formik.values.item_group ||
      !formik.values.unit
    ) {
      Swal.fire({
        title: 'Please complete the information.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      price: 0,
      qty: 0,
      item_group: '',
      unit: '',
      description: '',
      active: 1,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('item name is required'),
      price: Yup.number().min(0, 'price is required').required('price is required'),
      qty: Yup.number().required('qty is required'),
      item_group: Yup.number().min(1, 'item group is required').required('item group is required'),
      unit: Yup.number().min(1, 'unit is required').required('unit is required'),
    }),
    onSubmit: async (values) => {
      Swal.fire({
        title: 'Are you confirm to save?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await ItemSave(
              values.title,
              values.price,
              values.qty,
              values.item_group,
              values.unit,
              values.description,
              values.active
            )
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful created!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    navigate(PATH.ITEM_LIST)
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to save',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Item - create</h3>
            </div>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-danger shadow-sm me-2' onClick={comeback}>
                <FontAwesomeIcon icon={faXmark} className='fa-fw' />
                Cancel
              </button>
              <button type='submit' className='btn btn-primary shadow-sm' onClick={IsEmty}>
                <FontAwesomeIcon icon={faSave} className='fa-fw' />
                Save
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group mb-4'>
                  <label className='fw-bold required mb-2' htmlFor='title'>
                    Item (Part/Dwg no.)
                  </label>
                  <input
                    type='text'
                    id='title'
                    name='title'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <div className='text-danger'>{formik.errors.title}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group mb-4'>
                  <label className='fw-bold required mb-2' htmlFor='price'>
                    Price
                  </label>
                  <input
                    type='text'
                    id='price'
                    name='price'
                    onInput={(e) => {
                      numberOnly(e)
                    }}
                    className={'form-control form-control-sm'}
                    onChange={(e) => {
                      let input: any = e.target.value
                      formik.setFieldValue('price', input)
                    }}
                    onBlur={(e) => {
                      let input: any = e.target.value || 0
                      if (input % 1 == 0) {
                        formik.setFieldValue('price', input)
                      } else {
                        formik.setFieldValue('price', parseFloat(input).toFixed(3))
                      }
                    }}
                    value={formik.values.price}
                  />
                  {formik.touched.price && formik.errors.price ? (
                    <div className='text-danger'>{formik.errors.price}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group mb-4'>
                  <label className='fw-bold required mb-2' htmlFor='item_group'>
                    Item Group
                  </label>
                  <Select
                    options={groups}
                    isLoading={isLoading}
                    onChange={(option: any) => formik.setFieldValue('item_group', option.value)}
                    placeholder={'Please Select Item Group'}
                    styles={customStyles}
                  />
                  {formik.touched.item_group && formik.errors.item_group ? (
                    <div className='text-danger'>{formik.errors.item_group}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group mb-4'>
                  <label className='fw-bold required mb-2' htmlFor='unit'>
                    Unit
                  </label>
                  <Select
                    options={units}
                    onChange={(option: any) => formik.setFieldValue('unit', option.value)}
                    placeholder={'Please Select Unit'}
                    styles={customStyles}
                  />
                  {formik.touched.unit && formik.errors.unit ? (
                    <div className='text-danger'>{formik.errors.unit}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-4'>
                  <label className='fw-bold mb-2' htmlFor='description'>
                    Description (EN Name/Brand/Model/Color/Size/Other/TH Name)
                  </label>
                  <textarea
                    name='description'
                    id='description'
                    className={'form-control form-control-sm '}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.description && formik.errors.description ? (
                    <div className='text-danger'>{formik.errors.description}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-4'>
                  <label htmlFor='active' className='fw-bold mb-2'>
                    Active
                  </label>
                  <div className='mt-2'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        checked={checked}
                        type='checkbox'
                        id='active'
                        name='active'
                        value={formik.values.active}
                        onChange={(e) => {
                          setActive(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                  {formik.touched.active && formik.errors.active ? (
                    <div className='text-danger'>{formik.errors.active}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
  {
    title: 'item',
    path: PATH.ITEM_LIST,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const ItemCreate = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Item - Create</PageTitle>
      <ItemFrom />
    </>
  )
}

export default ItemCreate
