import {useState, useEffect, useRef} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {activeFormat} from '../../../modules/widgets/func/ActiveFormat'

const DeptShow = ({data}: any) => {
  const [show, setShow] = useState(false)
  const formButtonRef: any = useRef(null)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    formButtonRef.current.blur()
    setShow(true)
  }

  return (
    <>
      <button
        ref={formButtonRef}
        className='btn btn-icon btn-light-info  btn-sm me-2'
        onClick={() => {
          handleShow()
        }}
      >
        <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' />
      </button>
      <Modal show={show} onHide={handleClose} size='xl' centered>
        <Modal.Header closeButton>
          <Modal.Title>Departments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group mb-2'>
                <label htmlFor='dept_name' className='fw-bold fs-6 mb-2'>
                  Dept Name
                </label>
                <input
                  type='text'
                  id='dept_name'
                  className='form-control form-control-sm form-control-solid'
                  value={data.dept_name || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='form-group mb-2'>
                <label htmlFor='dept_no' className='fw-bold fs-6 mb-2'>
                  Dept no
                </label>
                <input
                  type='text'
                  id='dept_no'
                  className='form-control form-control-sm form-control-solid'
                  value={data.dept_no || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='form-group mb-2'>
                <label htmlFor='dept_alias_name' className='fw-bold fs-6 mb-2'>
                  Dept Alias Name
                </label>
                <input
                  type='text'
                  id='dept_alias_name'
                  className='form-control form-control-sm form-control-solid'
                  value={data.dept_alias_name || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='form-group mb-2'>
                <label htmlFor='header_dept_pr_no' className='fw-bold fs-6 mb-2'>
                  Header Dept PR no
                </label>
                <input
                  type='text'
                  id='header_dept_pr_no'
                  className='form-control form-control-sm form-control-solid'
                  value={data.header_dept_pr_no || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='form-group mb-2'>
                <label htmlFor='factory_no' className='fw-bold fs-6 mb-2'>
                  Factory no
                </label>
                <input
                  type='text'
                  id='factory_no'
                  className='form-control form-control-sm form-control-solid'
                  value={data.factory_no || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='form-group mb-2'>
                <label htmlFor='groupleader_increase' className='fw-bold fs-6 mb-2'>
                  Groupleader Increase
                </label>
                <input
                  type='number'
                  id='groupleader_increase'
                  className='form-control form-control-sm form-control-solid'
                  value={data.groupleader_increase || 0}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='form-group mb-2'>
                <label htmlFor='sectionhead_increase' className='fw-bold fs-6 mb-2'>
                  Sectionhead Increase
                </label>
                <input
                  type='number'
                  id='sectionhead_increase'
                  className='form-control form-control-sm form-control-solid'
                  value={data.sectionhead_increase || 0}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='form-group mb-2'>
                <label htmlFor='senior_increase' className='fw-bold fs-6 mb-2'>
                  Senior Increase
                </label>
                <input
                  type='number'
                  id='senior_increase'
                  className='form-control form-control-sm form-control-solid'
                  value={data.senior_increase || 0}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3'>
              <div className='form-group mb-2'>
                <label htmlFor='depthead_increase' className='fw-bold fs-6 mb-2'>
                  Depthead Increase
                </label>
                <input
                  type='number'
                  id='depthead_increase'
                  className='form-control form-control-sm form-control-solid'
                  value={data.depthead_increase || 0}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-group mb-2'>
                <label htmlFor='acc_remark' className='fw-bold fs-6 mb-2'>
                  Acc Remark
                </label>
                <textarea
                  id='acc_remark'
                  name='acc_remark'
                  className='form-control form-control-sm form-control-solid'
                  value={data.acc_remark}
                  readOnly
                ></textarea>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-group mb-2'>
                <label htmlFor='active' className='fw-bold fs-6 mb-2'>
                  Active
                </label>
                <div className='mt-2'>{activeFormat(data.active)}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {DeptShow}
