import {useState, useEffect, useMemo} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {QSCreateTable} from '../../modules/widgets/quotation/QSCreateTable'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faXmark, faPlus, faCopy} from '@fortawesome/free-solid-svg-icons'
import {QSStore} from '../../modules/api/requests/QuotationRequest'
import {DeptOption} from '../../modules/api/requests/DeptRequest'
import {ItemGroupOption} from '../../modules/api/requests/ItemGroupRequest'
import {ItemOption} from '../../modules/api/requests/ItemRequest'
import {UnitOption} from '../../modules/api/requests/UnitRequest'
import {BranchOption} from '../../modules/api/requests/BranchRequest'
import {SUPOption} from '../../modules/api/requests/SupplierRequest'
import {OpenFile} from '../../modules/widgets/func/OpenFile'
import {UploadFile} from '../../modules/widgets/quotation/func/UploadFile'
import Select from 'react-select'
import Swal from 'sweetalert2'
import {customStyles} from '../../modules/widgets/Styles/ReactSelect'
import {CovertTime, InputDate, CovertDate} from '../../modules/widgets/func/CoverTime'
import {BGLoad, BGClose} from '../../modules/widgets/func/BackgroundLoading'

import DatePicker from 'react-datepicker'

const QSForm = () => {
  const {currentUser} = useAuth()
  const [depts, setDepts] = useState([])
  const [igs, setIG] = useState([])
  const [igFull, setIGFull] = useState([])
  const [items, setItems] = useState([])
  const [units, setUnits] = useState([])
  const [branches, setBranches] = useState([])
  const [sups, setSub] = useState([])

  const gp: any = currentUser?.id || null

  const [data, setData] = useState<any>([
    {
      id: 1,
      requestor_id: gp,
      status: 'none',
      require_date: new Date(),
      dept_id: null,
      for_dept_id: null,
      urgency: 0,
      supplier_id: null,
      item_group_id: null,
      item_id: null,
      item_other: null,
      qty: 0,
      unit_id: null,
      file_url_pic: null,
      file_url_draw: null,
      file_url_qto: null,
      branch_id: null,
      box_item: false,
      box_sup: false,
      remark: null,
    },
  ])

  const xmarkIcon = <FontAwesomeIcon icon={faXmark} className='fa-fw fa-sm' />
  const plusIcon = <FontAwesomeIcon icon={faPlus} className='fa-fw fa-sm' />
  const saveIcon = <FontAwesomeIcon icon={faSave} className='fa-fw fa-sm' />
  const copyIcon = <FontAwesomeIcon icon={faCopy} className='fa-fw fa-sm' />

  const addRow = () => {
    let index = data.length + 1
    setData([
      ...data,
      {
        id: index,
        requestor_id: gp,
        status: 'none',
        require_date: new Date(),
        dept_id: null,
        for_dept_id: null,
        urgency: 0,
        supplier_id: null,
        supplier_other: null,
        item_group_id: null,
        item_id: null,
        item_other: null,
        qty: 0,
        unit_id: null,
        file_url_pic: null,
        file_url_draw: null,
        file_url_qto: null,
        branch_id: null,
        box_item: false,
        box_sup: false,
        remark: null,
      },
    ])
  }

  const deleteRow = (id: number) => {
    setData((data: any) => data.filter((val: any) => val.id !== id) || data)
  }

  const copyRow = () => {
    if (data.length > 0) {
      const res: any = data[data.length - 1]
      if (typeof res !== 'undefined' && res != null) {
        setData([
          ...data,
          {
            id: res.id + 1,
            requestor_id: res.requestor_id,
            status: res.status,
            require_date: res.require_date,
            dept_id: res.dept_id,
            for_dept_id: res.for_dept_id,
            urgency: res.urgency,
            supplier_id: res.supplier_id,
            supplier_other: res.supplier_other,
            item_group_id: res.item_group_id,
            item_id: res.item_id,
            item_other: res.item_other,
            qty: res.qty,
            unit_id: res.unit_id,
            file_url_pic: res.file_url_pic,
            file_url_draw: res.file_url_draw,
            file_url_qto: res.file_url_qto,
            branch_id: res.branch_id,
            box_item: res.box_item,
            box_sup: res.box_sup,
            remark: res.remark,
          },
        ])
      }
      console.log(res)
    }
  }

  const handleData = (id: number, key: string, value: any) => {
    setData((data: any) =>
      data.map((val: any) => {
        if (val.id == id) {
          if (key == 'urgency') {
            if (value == true) {
              val.urgency = 1
            } else {
              val.urgency = 0
            }
          } else if (key == 'item_id') {
            val.item_id = value
            val.item_other = null
          } else if (key == 'item_other') {
            val.item_id = null
            val.item_other = value
          } else if (key == 'supplier_id') {
            val.supplier_id = value
            val.supplier_other = null
          } else if (key == 'supplier_other') {
            val.supplier_id = null
            val.supplier_other = value
          } else {
            val[key] = value
          }
        }
        return val
      })
    )
  }

  const changeBox = (id: number, value: any) => {
    const res: any = igFull.find((val: any) => val.id == value)
    if (res.custom_item != '' || typeof res.custom_item != 'undefined') {
      if (res.custom_item == 1) {
        handleData(id, 'box_item', true)
      } else {
        handleData(id, 'box_item', false)
      }
    }
  }

  const getDept = async () => {
    let options: any = []
    const res: any = await DeptOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.dept_name})
      })
      setDepts(options)
    }
  }

  const getIG = async () => {
    let options: any = []
    const res: any = await ItemGroupOption()
    setIGFull(res.data.data)
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setIG(options)
    }
  }

  const getItem = async () => {
    let options: any = []
    const res: any = await ItemOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setItems(options)
    }
  }

  const getUnit = async () => {
    let options: any = []
    const res: any = await UnitOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setUnits(options)
    }
  }

  const getBranch = async () => {
    let options: any = []
    const res: any = await BranchOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setBranches(options)
    }
  }

  const getSub = async () => {
    let options: any = []
    const res: any = await SUPOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setSub(options)
    }
  }

  const numberOnly = (event: any) => {
    let input = event.target.value
    event.target.value = input
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/^0[^.]/, '0')
  }

  const Submit = () => {
    try {
      if (data.length > 0) {
        let status: boolean = true
        let row: string = 'Row'
        data.map((val: any, key: number) => {
          if (
            val.requestor_id == null ||
            val.require_date == null ||
            val.dept_id == null ||
            val.for_dept_id == null ||
            val.urgency == null ||
            (val.supplier_id == null && val.supplier_other == null) ||
            val.item_group_id == null ||
            (val.item_id == null && val.item_other == null) ||
            val.qty == null ||
            val.unit_id == null ||
            val.branch_id == null
          ) {
            status = false
            if (row == 'Row') {
              row = row + ' ' + (key + 1)
            } else {
              row = row + ', ' + (key + 1)
            }
          } else {
            val.require_date = InputDate(val.require_date)
          }
        })
        if (status) {
          Swal.fire({
            title: 'Are you confirm to save?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            heightAuto: false,
            returnFocus: false,
            showLoaderOnConfirm: true,
            preConfirm: (e) => {
              return new Promise(async function (resolve) {
                await QSStore(data)
                  .then((res) => {
                    if (res.data.status == true) {
                      Swal.fire({
                        title: 'Successful updated!',
                        icon: 'success',
                        showConfirmButton: false,
                        heightAuto: false,
                        timer: 1500,
                        returnFocus: false,
                      }).then(() => {
                        setData((data: any) => [])
                        window.setTimeout(() => {
                          setData(() => [
                            {
                              id: 1,
                              requestor_id: gp,
                              status: 'none',
                              require_date: null,
                              dept_id: null,
                              for_dept_id: null,
                              urgency: 0,
                              supplier_id: null,
                              supplier_other: null,
                              item_group_id: null,
                              item_id: null,
                              item_other: null,
                              qty: 0,
                              unit_id: null,
                              file_url_pic: null,
                              file_url_draw: null,
                              file_url_qto: null,
                              branch_id: null,
                              box_item: false,
                              remark: null,
                            },
                          ])
                        }, 100)
                      })
                    } else {
                      Swal.fire({
                        title: res.data.message,
                        icon: 'error',
                        heightAuto: false,
                        returnFocus: false,
                      })
                    }
                  })
                  .catch((err) => {
                    Swal.fire({
                      title: 'Failed to save',
                      icon: 'error',
                      heightAuto: false,
                      returnFocus: false,
                    })
                  })
              })
            },
          })
        } else {
          Swal.fire({
            title: 'Please complete the information.',
            text: row,
            icon: 'error',
            heightAuto: false,
            returnFocus: false,
          })
        }
      } else {
        Swal.fire({
          title: 'Please add the information.',
          text: '',
          icon: 'error',
          heightAuto: false,
          returnFocus: false,
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: <>Section/Department</>,
        id: 'dept',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <Select
            options={depts}
            menuPortalTarget={document.body}
            onChange={(option: any) => {
              handleData(cell.row.original.id, 'dept_id', option.value)
            }}
            value={
              depts ? depts.find((option: any) => option.value == cell.row.original.dept_id) : ''
            }
            styles={customStyles}
          />
        ),
      },
      {
        Header: <>For&nbsp;Dept.</>,
        id: 'for_dept',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <Select
            options={depts}
            menuPortalTarget={document.body}
            onChange={(option: any) => {
              handleData(cell.row.original.id, 'for_dept_id', option.value)
            }}
            value={
              depts
                ? depts.find((option: any) => option.value == cell.row.original.for_dept_id)
                : ''
            }
            styles={customStyles}
          />
        ),
      },
      {
        Header: <>Urgent</>,
        id: 'urgent',
        Cell: ({cell}: any) => (
          <>
            <div className='text-center'>
              <input
                type='checkbox'
                className='form-check-input'
                onChange={(e) => {
                  handleData(cell.row.original.id, 'urgency', e.target.checked)
                }}
                checked={cell.row.original.urgency == 1 ? true : false}
              />
            </div>
          </>
        ),
      },
      {
        Header: <>Group&nbsp;Item</>,
        id: 'item_group',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <Select
            options={igs}
            menuPortalTarget={document.body}
            onChange={(option: any) => {
              handleData(cell.row.original.id, 'item_group_id', option.value)
              changeBox(cell.row.original.id, option.value)
            }}
            value={
              igs ? igs.find((option: any) => option.value == cell.row.original.item_group_id) : ''
            }
            styles={customStyles}
          />
        ),
      },
      {
        Header: <>Item&nbsp;Description</>,
        id: 'item',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <>
            {cell.row.original.box_item ? (
              <input
                type='text'
                id='item_other'
                className='form-control form-control-sm border border-danger'
                placeholder='Please fill out'
                onChange={(e) => {
                  handleData(cell.row.original.id, 'item_other', e.target.value)
                }}
                value={cell.row.original.item_other || ''}
              />
            ) : (
              <Select
                options={items}
                menuPortalTarget={document.body}
                onChange={(option: any) => {
                  handleData(cell.row.original.id, 'item_id', option.value)
                }}
                value={
                  items
                    ? items.find((option: any) => option.value == cell.row.original.item_id)
                    : ''
                }
                styles={customStyles}
              />
            )}
          </>
        ),
      },
      {
        Header: <>Qty.</>,
        id: 'qty',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => (
          <>
            <input
              type='text'
              maxLength={10}
              onInput={(e) => {
                numberOnly(e)
              }}
              className='form-control form-control-sm'
              onChange={(e) => {
                let input: any = e.target.value
                handleData(cell.row.original.id, 'qty', input)
              }}
              onBlur={(e) => {
                let input: any = e.target.value || 0
                if (input % 1 == 0) {
                  handleData(cell.row.original.id, 'qty', input)
                } else {
                  handleData(cell.row.original.id, 'qty', parseFloat(input).toFixed(3))
                }
              }}
              value={cell.row.original.qty}
            />
          </>
        ),
      },
      {
        Header: <>Unit</>,
        id: 'unit',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <Select
            options={units}
            menuPortalTarget={document.body}
            onChange={(option: any) => {
              handleData(cell.row.original.id, 'unit_id', option.value)
            }}
            value={
              units ? units.find((option: any) => option.value == cell.row.original.unit_id) : ''
            }
            styles={customStyles}
          />
        ),
      },
      {
        Header: <>Supplier&nbsp;name</>,
        id: 'supplier',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <>
            {cell.row.original.box_sup ? (
              <input
                type='text'
                className='form-control form-control-sm border border-danger'
                placeholder='Please fill out'
                value={cell.row.original.supplier_other}
                onChange={(e) => {
                  handleData(cell.row.original.id, 'supplier_other', e.target.value)
                }}
              />
            ) : (
              <Select
                options={sups}
                menuPortalTarget={document.body}
                onChange={(option: any) => {
                  handleData(cell.row.original.id, 'supplier_id', option.value)
                }}
                value={
                  sups
                    ? sups.find((option: any) => option.value == cell.row.original.supplier_id)
                    : ''
                }
                styles={customStyles}
              />
            )}
          </>
        ),
      },
      {
        Header: <>Supplier&nbsp;Other</>,
        id: 'supplier_other',
        headerClassName: 'min-w-50px',
        Cell: ({cell}: any) => (
          <>
            <div className='text-center'>
              <input
                type='checkbox'
                className='form-check-input'
                onChange={(e) => {
                  if (e.target.checked == true) {
                    handleData(cell.row.original.id, 'supplier_id', null)
                    handleData(cell.row.original.id, 'supplier_other', null)
                    handleData(cell.row.original.id, 'box_sup', true)
                  } else {
                    handleData(cell.row.original.id, 'supplier_id', null)
                    handleData(cell.row.original.id, 'supplier_other', null)
                    handleData(cell.row.original.id, 'box_sup', false)
                  }
                }}
                checked={cell.row.original.box_sup}
              />
            </div>
          </>
        ),
      },
      {
        Header: <>Picture(FA)</>,
        id: 'pic_file',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <>
            <div className='d-flex justify-content-center align-items-sm-start flex-shrink-0'>
              <button
                className='btn btn-secondary btn-sm text-primary me-2'
                onClick={() => {
                  UploadFile('pic', cell.row.original.id, setData)
                }}
                onFocusCapture={() => {
                  BGClose()
                }}
              >
                Upload
              </button>
              {cell.row.original.file_url_pic
                ? OpenFile(cell.row.original.file_url_pic, 'pic')
                : ''}
            </div>
          </>
        ),
      },
      {
        Header: <>Drawing(FA)</>,
        id: 'draw_file',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <>
            <div className='d-flex justify-content-center align-items-sm-start flex-shrink-0'>
              <button
                className='btn btn-secondary btn-sm text-primary me-2'
                onClick={() => {
                  UploadFile('draw', cell.row.original.id, setData)
                }}
                onFocusCapture={() => {
                  BGClose()
                }}
              >
                Upload
              </button>
              {cell.row.original.file_url_draw
                ? OpenFile(cell.row.original.file_url_draw, 'draw')
                : ''}
            </div>
          </>
        ),
      },
      {
        Header: <>Quotation&nbsp;by&nbsp;User</>,
        id: 'qs_file',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <>
            <div className='d-flex justify-content-center align-items-sm-start flex-shrink-0'>
              <button
                className='btn btn-secondary btn-sm text-primary me-2'
                onClick={() => {
                  UploadFile('qs', cell.row.original.id, setData)
                }}
                onFocusCapture={() => {
                  BGClose()
                }}
              >
                Upload
              </button>
              {cell.row.original.file_url_qto
                ? OpenFile(cell.row.original.file_url_qto, 'quotation')
                : ''}
            </div>
          </>
        ),
      },
      {
        Header: <>For&nbsp;Branch</>,
        id: 'branch',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <Select
            options={branches}
            menuPortalTarget={document.body}
            onChange={(option: any) => {
              handleData(cell.row.original.id, 'branch_id', option.value)
            }}
            value={
              branches
                ? branches.find((option: any) => option.value == cell.row.original.branch_id)
                : ''
            }
            styles={customStyles}
          />
        ),
      },
      {
        Header: <>Required&nbsp;Date</>,
        id: 'require_date',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <>
            <DatePicker
              className='form-control form-control-sm'
              selected={cell.row.original.require_date}
              onChange={(date: any) => {
                handleData(cell.row.original.id, 'require_date', date)
              }}
              dateFormat='yyyy/MM/dd'
              maxDate={new Date('12-31-9999')}
              placeholderText='yyyy/mm/dd'
            />
          </>
        ),
      },
      {
        Header: <>Remark</>,
        id: 'remark',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <>
            <textarea
              rows={1}
              className='form-control form-control-sm'
              value={cell.row.original.remark}
              onChange={(e: any) => {
                handleData(cell.row.original.id, 'remark', e.target.value)
              }}
              placeholder='Please fill in the remarks.'
            />
          </>
        ),
      },
      {
        Header: <>ACTION</>,
        id: 'action',
        Cell: ({cell}: any) => (
          <>
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                className='btn btn-icon btn-light-danger  btn-sm me-2'
                onClick={() => {
                  deleteRow(cell.row.original.id)
                }}
              >
                {xmarkIcon}
              </button>
            </div>
          </>
        ),
      },
    ],
    [depts, igs, items, units, branches, sups]
  )

  useEffect(() => {
    getDept()
    getIG()
    getItem()
    getUnit()
    getBranch()
    getSub()
  }, [])

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <QSCreateTable columns={columns} data={data} />
        </div>
        <div className='card-footer'>
          <div className='text-end'>
            <button className='btn btn-secondary me-2' onClick={copyRow}>
              {copyIcon} Copy Row
            </button>
            <button className='btn btn-primary text-white me-2' onClick={addRow}>
              {plusIcon} Add Row
            </button>
            <button className='btn btn-success text-white' onClick={Submit}>
              {saveIcon} Save
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const QSCreate = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Quotation Requisition</PageTitle>
      <QSForm />
      <BGLoad />
    </>
  )
}

export default QSCreate
