import clsx from 'clsx'

const Pagination = ({
  pageOptions,
  pageIndex,
  gotoPage,
  previousPage,
  nextPage,
  pageSize,
  setPageSize,
  canPreviousPage,
  canNextPage,
  pageCount,
}: any) => {
  return (
    <>
      <div className='row mt-2'>
        <div className='col-md-6 mb-2'>
          <div className='d-flex justify-content-sm-start align-items-sm-center'>
            <div className='me-1'>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </div>
            <div className='d-flex align-items-sm-center me-1'>
              <div>| Go to page:&nbsp;&nbsp;</div>
              <input
                type='number'
                className='form-control form-control-sm w-100px'
                min={0}
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                // value={pageIndex + 1}
              />
            </div>
            <select
              className='form-select form-select-sm w-125px me-1'
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize} style={{width: '100px'}}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-md-6 mb-2'>
          <div className='text-end'>
            <button
              className='btn btn-primary btn-sm me-1'
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {'<<'}
            </button>{' '}
            <button
              className='btn btn-primary btn-sm me-1'
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {'<'}
            </button>{' '}
            <button
              className='btn btn-primary btn-sm me-1'
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {'>'}
            </button>{' '}
            <button
              className='btn btn-primary btn-sm me-1'
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>{' '}
          </div>
        </div>
      </div>
    </>
  )
}

export {Pagination}
