import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const EXPORT_QS = `${API_URL}/export/quotation`
export const EXPORT_DEPT = `${API_URL}/export/department`
export const EXPORT_BRANCH = `${API_URL}/export/branch`
export const EXPORT_DC = `${API_URL}/export/document-concerned`
export const EXPORT_UNIT = `${API_URL}/export/unit`
export const EXPORT_ITEM = `${API_URL}/export/item`
export const EXPORT_IG = `${API_URL}/export/item-group`
export const EXPORT_SUP = `${API_URL}/export/supplier`
export const EXPORT_SG = `${API_URL}/export/supplier-group`
export const EXPORT_LOG = `${API_URL}/export/log`
export const EXPORT_ROLE = `${API_URL}/export/role`
export const EXPORT_USER = `${API_URL}/export/user`
export const EXPORT_QS_CANCEL = `${API_URL}/export/quotation-cancel`

export function ExportQS(requestor_id?: number) {
  return axios.get(EXPORT_QS + `/${requestor_id}}`)
}

export function ExportDept() {
  return axios.get(EXPORT_DEPT)
}

export function ExportBranch() {
  return axios.get(EXPORT_BRANCH)
}

export function ExportDC() {
  return axios.get(EXPORT_DC)
}

export function ExportUnit() {
  return axios.get(EXPORT_UNIT)
}

export function ExportItem() {
  return axios.get(EXPORT_ITEM)
}

export function ExportIG() {
  return axios.get(EXPORT_IG)
}

export function ExportSUP() {
  return axios.get(EXPORT_SUP)
}

export function ExportSG() {
  return axios.get(EXPORT_SG)
}

export function ExportLog() {
  return axios.get(EXPORT_LOG)
}

export function ExportRole() {
  return axios.get(EXPORT_ROLE)
}

export function ExportUser() {
  return axios.get(EXPORT_USER)
}

export function ExportQSCancel() {
  return axios.get(EXPORT_QS_CANCEL)
}
