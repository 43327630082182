import {useCallback, useMemo, useRef, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {QuotationCancelList} from '../../modules/api/requests/QuotationRequest'
import {ReturnNull} from '../../modules/widgets/func/ReturnNull'
import {ReadMore} from '../../modules/widgets/func/ReadMore'
import {statusFormat} from '../../modules/widgets/quotation/func/QSStatus'
import {CoverTime3, CoverTime4} from '../../modules/widgets/func/CoverTime'
import {QSCancelTable} from '../../modules/widgets/quotation/QSCancelTable'
import {QSShow} from './Show'

interface fetchAPI {
  limit: any
  skip: any
  search: any
  newdate: any
}

const CancelForm = () => {
  // --- state
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [filters, setFilters] = useState('')
  const [index, setIndex] = useState(0)
  const [size, setSize] = useState(0)

  // --- ref
  const fetchIdRef = useRef(0)

  // --- fetch API
  const fetchAPI = async ({limit, skip, search, newdate}: fetchAPI) => {
    try {
      const res: any = await QuotationCancelList({
        params: {
          limit: limit,
          skip: skip,
          search: encodeURIComponent(JSON.stringify(search || null)),
          newdate: encodeURIComponent(JSON.stringify(newdate || null)),
        },
      })
      let data: any = res.data.data
      let count: number = res.data.count
      count = count / 1
      setData(data)
      setPageCount(Math.ceil(count / limit))
      setLoading(false)
    } catch (err) {
      console.log('Error while fecth')
    }
  }

  const fetchData = useCallback(({pageSize, pageIndex, filter, newdate, status}: any) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)
    setIndex(pageIndex)
    setSize(pageSize)
    setFilters(filter)
    if (fetchId == fetchIdRef.current) {
      fetchAPI({
        limit: pageSize,
        skip: pageSize * pageIndex,
        search: filter,
        newdate: newdate,
      })
    }
  }, [])

  // --- columns
  const columns = useMemo(
    () => [
      {
        Header: <div className='min-w-150px'>Branch</div>,
        accessor: 'branch_title',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {ReadMore(cell.row.original.branch_title)}
          </span>
        ),
      },
      {
        Header: <div>Section/Department</div>,
        id: 'dept_id',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {ReadMore(cell.row.original.dept_title)}
          </span>
        ),
      },
      {
        Header: <div>GP&nbsp;no.</div>,
        accessor: 'job_title',
        headerClassName: 'min-w-50px headcol',
        columnClassName: 'headcol',
        Cell: ({cell}: any) => (
          <span className={cell.row.original.urgency == 1 ? 'text-primary' : ''}>
            {ReturnNull(cell.row.original.job_title)}
          </span>
        ),
      },
      {
        Header: <div>Job&nbsp;User&nbsp;no.</div>,
        accessor: 'job_user_no',
        headerClassName: 'min-w-50px',
        Cell: ({cell}: any) => (
          <span className={cell.row.original.urgency == 1 ? 'text-primary' : ''}>
            {ReturnNull(cell.row.original.job_user_no)}
          </span>
        ),
      },
      {
        Header: <div>For&nbsp;dept.</div>,
        id: 'for_dept_id',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {ReadMore(cell.row.original.for_dept_title)}
          </span>
        ),
      },
      {
        Header: <div>Group&nbsp;from&nbsp;System</div>,
        accessor: 'ig_title',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {ReadMore(cell.row.original.ig_title)}
          </span>
        ),
      },
      {
        Header: <div>Item</div>,
        id: 'item_title',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {ReadMore(cell.row.original.item_title || cell.row.original.item_other)}
          </span>
        ),
      },
      {
        Header: <div>Item&nbsp;Description</div>,
        id: 'item_description',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {ReadMore(cell.row.original.item_description)}
          </span>
        ),
      },
      {
        Header: <div>Request&nbsp;Date</div>,
        id: 'created_at',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {CoverTime3(cell.row.original.created_at)}
          </span>
        ),
      },
      {
        Header: <div>Status</div>,
        id: 'status',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => {
          return (
            <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
              {statusFormat(cell.row.original.status, cell.row.original)}
            </span>
          )
        },
      },
      {
        Header: <div>Comment</div>,
        id: 'comment',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {ReadMore(cell.row.original.gp_verify_comment)}
          </span>
        ),
      },
      {
        Header: <div>Canceled&nbsp;by</div>,
        id: 'user_updated_by_name',
        headerClassName: 'min-w-200px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {cell.row.original.user_updated_by_name
              ? `${
                  cell.row.original?.user_updated_by_name +
                  ' ' +
                  cell.row.original?.user_updated_by_lastname
                }`
              : '-'}
          </span>
        ),
      },
      {
        Header: <div>Canceled&nbsp;Date</div>,
        id: 'updated_at',
        headerClassName: 'min-w-150px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-primary' : ''}`}>
            {CoverTime4(cell.row.original.updated_at)}
          </span>
        ),
      },
      {
        Header: 'Action',
        id: 'action',
        headerClassName: 'text-end headcol-right',
        columnClassName: 'rowcol-right',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-center'>
            <QSShow data={cell.row.original} className='m-none' />
          </div>
        ),
      },
    ],
    []
  )

  return (
    <QSCancelTable
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
    />
  )
}

const CancelList = () => {
  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
      isActive: false,
    },
    {
      title: '',
      path: '',
      isActive: false,
      isSeparator: true,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Cancel List</PageTitle>
      <CancelForm />
    </>
  )
}

export default CancelList
