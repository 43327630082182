import {useState, useRef} from 'react'
import {CSVLink} from 'react-csv'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileExport} from '@fortawesome/free-solid-svg-icons'
import {useAuth} from '../../modules/auth'
import {ExportQS, ExportQSCancel} from '../../modules/api/requests/ExportDataRequest'

interface QSExportDataProps {
  status?: string
}

const QSExportData = ({status}: QSExportDataProps) => {
  const {currentUser} = useAuth()
  const [data, setData] = useState<any>([])
  const csvLink: any = useRef()

  const name = 'quotations'
  const requestor_id: number = currentUser?.id || 0

  const headers = [
    {label: 'ID', key: 'index'},
    {label: 'Job no', key: 'job_title'},
    {label: 'Job User no', key: 'job_user_no'},
    {label: 'Dept./Sect. Code', key: 'dept_title'},
    {label: 'For dept', key: 'for_dept_title'},
    {label: 'Group from System', key: 'ig_title'},
    {label: 'Item', key: 'item_title'},
    {label: 'Item Description', key: 'item_description'},
    {label: 'Request Date', key: 'created_at'},
    {label: 'Finished Date', key: 'finished_date'},
    {label: 'Status', key: 'status'},
    {label: 'Comment', key: 'gp_verify_comment'},
    {label: 'PIC', key: 'pic'},
    {label: 'PR.no', key: 'gp_verify_id'},
  ]

  const getData = async () => {
    if (status === 'cancel') {
      await ExportQSCancel()
        .then((res) => {
          let rawdata = res.data.data
          const csvData = rawdata.map((row: any) => {
            return {
              index: `=""${row.index || '-'}""`,
              job_title: `=""${row.job_title || '-'}""`,
              job_user_no: `=""${row.job_user_no || '-'}""`,
              dept_title: `=""${row.dept_title || '-'}""`,
              for_dept_title: `=""${row.for_dept_title || '-'}""`,
              ig_title: `=""${row.ig_title || '-'}""`,
              item_title: `=""${row.item_title}""`,
              item_description: `=""${row.item_description || '-'}""`,
              finished_date: `=""${row.finished_date || '-'}""`,
              status: `=""${row.status || '-'}""`,
              gp_verify_comment: `=""${row.gp_verify_comment || '-'}""`,
              pic: `=""${row.pic || '-'}""`,
              gp_verify_id: `=""${row.gp_verify_id || '-'}""`,
              created_by: `=""${row.created_by || '-'}""`,
              created_at: `=""${row.created_at || '-'}""`,
              updated_by: `=""${row.updated_by || '-'}""`,
              updated_at: `=""${row.updated_at || '-'}""`,
            }
          })
          setData(csvData)
          setTimeout(() => {
            csvLink.current.link.click()
          }, 200)
        })
        .catch((err) => {
          setData([])
        })
    } else {
      await ExportQS(requestor_id)
        .then((res) => {
          let rawdata = res.data.data
          const csvData = rawdata.map((row: any) => {
            return {
              index: `=""${row.index || '-'}""`,
              job_title: `=""${row.job_title || '-'}""`,
              job_user_no: `=""${row.job_user_no || '-'}""`,
              dept_title: `=""${row.dept_title || '-'}""`,
              for_dept_title: `=""${row.for_dept_title || '-'}""`,
              ig_title: `=""${row.ig_title || '-'}""`,
              item_title: `=""${row.item_title}""`,
              item_description: `=""${row.item_description || '-'}""`,
              finished_date: `=""${row.finished_date || '-'}""`,
              status: `=""${row.status || '-'}""`,
              gp_verify_comment: `=""${row.gp_verify_comment || '-'}""`,
              pic: `=""${row.pic || '-'}""`,
              gp_verify_id: `=""${row.gp_verify_id || '-'}""`,
              created_by: `=""${row.created_by || '-'}""`,
              created_at: `=""${row.created_at || '-'}""`,
              updated_by: `=""${row.updated_by || '-'}""`,
              updated_at: `=""${row.updated_at || '-'}""`,
            }
          })
          setData(csvData)
          setTimeout(() => {
            csvLink.current.link.click()
          }, 200)
        })
        .catch((err) => {
          setData([])
        })
    }
  }

  return (
    <>
      <button
        className='btn btn-light-success'
        onClick={() => {
          getData()
        }}
      >
        <FontAwesomeIcon icon={faFileExport} className='fa-fw me-1' />
        Export&nbsp;All
      </button>
      <CSVLink
        data={data}
        filename={name || 'data.csv'}
        headers={headers}
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </>
  )
}

export {QSExportData}
