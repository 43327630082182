import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const UNDO_JOB_LIST = `${API_URL}/undo-job-list`
export const UNDO_QUOTATION_LIST = `${API_URL}/undo-quotation-list`

export function UndoJobList(job_id: number) {
  return axios.get(UNDO_JOB_LIST + `/${job_id}`)
}

export function UndoQuotationList(quotation_id: number) {
  return axios.get(UNDO_QUOTATION_LIST + `/${quotation_id}`)
}

