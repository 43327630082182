const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const addZero = (i: number) => {
  let index: string = ''
  if (i < 10) {
    index = '0' + i
  } else {
    index = '' + i
  }
  return index
}

const CoverTime = (time: string) => {
  if (time == '' || time == null || typeof time === 'undefined') {
    return <div className='text-center'>-</div>
  } else {
    let current: any = new Date(time)
    return (
      <div>
        {addZero(current.getDate())}&nbsp;{monthNames[current.getMonth()]}&nbsp;
        {current.getFullYear()}
      </div>
    )
  }
}

const CoverTime2 = (time: string) => {
  if (time == '' || time == null || typeof time === 'undefined') {
    return <div className='text-center'>-</div>
  } else {
    let current: any = new Date(time)
    return (
      <>
        {addZero(current.getDate()) +
          '/' +
          addZero(current.getMonth() + 1) +
          '/' +
          current.getFullYear()}
      </>
    )
  }
}

const CoverTime3 = (time: string) => {
  if (time == '' || time == null || typeof time === 'undefined') {
    return <div className='text-center'>-</div>
  } else {
    let current: any = new Date(time)
    return (
      <>
        {current.getFullYear() +
          '/' +
          addZero(current.getMonth() + 1) +
          '/' +
          addZero(current.getDate())}
      </>
    )
  }
}

const CoverTime4 = (time: string) => {
  if (time == '' || time == null || typeof time === 'undefined') {
    return <div className=''>-</div>
  } else {
    let current: any = new Date(time)
    return (
      <>
        {current.getFullYear() +
          '/' +
          addZero(current.getMonth() + 1) +
          '/' +
          addZero(current.getDate()) +
          ' ' +
          addZero(current.getHours()) +
          ':' +
          addZero(current.getMinutes())}
      </>
    )
  }
}

const CovertTime = (time: string) => {
  if (time == '' || time == null || typeof time === 'undefined') {
    return ''
  } else {
    let current: any = new Date(time)
    return (
      current.getFullYear() +
      '/' +
      addZero(current.getMonth() + 1) +
      '/' +
      addZero(current.getDate()) +
      ' ' +
      addZero(current.getHours()) +
      ':' +
      addZero(current.getMinutes())
    )
  }
}

const CovertDate = (time: string) => {
  if (time == '' || time == null || typeof time === 'undefined') {
    return ''
  } else {
    let current: any = new Date(time)
    return (
      current.getFullYear() +
      '/' +
      addZero(current.getMonth() + 1) +
      '/' +
      addZero(current.getDate())
    )
  }
}

const InputDate = (date: string) => {
  if (date == '' || date == null || typeof date === 'undefined') {
    return ''
  } else {
    let current: any = new Date(date)
    return (
      current.getFullYear() +
      '-' +
      addZero(current.getMonth() + 1) +
      '-' +
      addZero(current.getDate())
    )
  }
}

const CovertTime5 = (time: string) => {
  if (time == '' || time == null || typeof time === 'undefined') {
    return ''
  } else {
    let current: any = new Date(time)
    return (
      current.getFullYear() +
      '-' +
      addZero(current.getMonth() + 1) +
      '-' +
      addZero(current.getDate())
    )
  }
}

const CovertTime6 = (time: string) => {
  if (time == '' || time == null || typeof time === 'undefined') {
    return ''
  } else {
    let current: any = new Date(time)
    return (
      current.getFullYear() +
      '-' +
      addZero(current.getMonth() + 1) +
      '-' +
      addZero(current.getDate()) +
      ` ${addZero(current.getHours())}:${addZero(current.getMinutes())}`
    )
  }
}

export {
  CoverTime,
  CoverTime2,
  CoverTime3,
  CoverTime4,
  CovertTime,
  InputDate,
  CovertDate,
  CovertTime5,
  CovertTime6,
}
