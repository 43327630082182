import {useState, useEffect} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faXmark} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import {UnitSave} from '../../../modules/api/requests/UnitRequest'
import * as PATH from '../../../modules/path/Path'
import {useCurrentPage} from '../HistoryPage'

const UnitForm = () => {
  const navigate = useNavigate()
  const [checked, setChecked] = useState(true)
  const {currentPage, setCurrentPage, currentPages} = useCurrentPage()

  const getPage = () => {
    if (currentPages) {
      currentPages.page = 'units'
      currentPages.status = false
    }
  }

  useEffect(() => {
    getPage()
  }, [])

  const setActive = async (ac: any) => {
    if (ac == true) {
      formik.setFieldValue('active', 1)
    } else if (ac == false) {
      formik.setFieldValue('active', 0)
    }
    setChecked(!checked)
  }

  const comeback = () => {
    if (
      formik.values.title != '' ||
      formik.values.description != '' ||
      formik.values.uom != '' ||
      formik.values.active != 1
    ) {
      Swal.fire({
        title: 'Are you confirm to cancel?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(PATH.UNIT_LIST)
        }
      })
    } else {
      navigate(PATH.UNIT_LIST)
    }
  }

  const IsEmty = () => {
    if (
      formik.values.title == '' ||
      typeof formik.values.title == undefined ||
      formik.values.title == null
    ) {
      Swal.fire({
        title: 'Please complete the information.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      uom: '',
      description: '',
      active: 1,
    },
    validationSchema: Yup.object({
      title: Yup.string().max(255, 'Must be 255 characters or less').required('title is required'),
    }),
    onSubmit: async (values) => {
      Swal.fire({
        title: 'Are you confirm to save?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await UnitSave(values.title, values.uom, values.description, values.active)
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful created!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    navigate(PATH.UNIT_LIST)
                  })
                } else {
                  Swal.fire({
                    title: res.data.message,
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to save',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Unit - Create</h3>
            </div>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-danger shadow-sm me-2' onClick={comeback}>
                <FontAwesomeIcon icon={faXmark} className='fa-fw' />
                Cancel
              </button>
              <button type='submit' className='btn btn-primary shadow-sm' onClick={IsEmty}>
                <FontAwesomeIcon icon={faSave} className='fa-fw' />
                Save
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-12'>
                {/* title */}
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold required mb-2'>
                    Title
                  </label>
                  <input
                    type='text'
                    id='title'
                    name='title'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <div className='text-danger'>{formik.errors.title}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                {/* uom */}
                <div className='form-group mb-4'>
                  <label htmlFor='uom' className='fw-bold mb-2'>
                    UOM
                  </label>
                  <input
                    type='text'
                    id='uom'
                    name='uom'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.uom && formik.errors.uom ? (
                    <div className='text-danger'>{formik.errors.uom}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                {/* description */}
                <div className='form-group mb-4'>
                  <label htmlFor='title' className='fw-bold mb-2'>
                    Unit Description
                  </label>
                  <textarea
                    id='description'
                    name='description'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.description && formik.errors.description ? (
                    <div className='text-danger'>{formik.errors.description}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12'>
                {/* active */}
                <div className='form-group mb-4'>
                  <label htmlFor='active' className='fw-bold mb-2'>
                    Active
                  </label>
                  <div className='mt-2'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        checked={checked}
                        type='checkbox'
                        id='active'
                        name='active'
                        value={formik.values.active}
                        onChange={(e) => {
                          setActive(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                  {formik.touched.active && formik.errors.active ? (
                    <div className='text-danger'>{formik.errors.active}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
  {
    title: 'Unit',
    path: PATH.UNIT_LIST,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const UnitCreate = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Unit - Create</PageTitle>
      <UnitForm />
    </>
  )
}

export default UnitCreate
