import {useEffect, useState} from 'react'
import {AddSupplier} from '../../../modules/widgets/quotation/verify/AddSupplier'
import {SUPOption} from '../../../modules/api/requests/SupplierRequest'
import Select from 'react-select'
import Swal from 'sweetalert2'
import {UploadSUP} from '../../../modules/api/requests/QuotationRequest'
import {customStyles} from '../../../modules/widgets/Styles/ReactSelect'
import {BGClose} from '../../../modules/widgets/func/BackgroundLoading'
import {OpenFile} from '../../../modules/widgets/func/OpenFile'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faX} from '@fortawesome/free-solid-svg-icons'

const QuotationAttachment = ({data, setAttachment}: any) => {
  const [sup, setSup] = useState([])

  const [jobitem, setJobItem] = useState({
    id: null,
    gp_supplier_choose_id_one: 0,
    gp_supplier_other_one: '',
    gp_supplier_choose_id_sec: 0,
    gp_supplier_other_sec: '',
    gp_supplier_choose_id_thr: 0,
    gp_supplier_other_thr: '',
    gp_supplier_choose_file_one: '',
    gp_supplier_choose_file_sec: '',
    gp_supplier_choose_file_thr: '',
  })

  const [boxsup1, setBoxSup1] = useState(false)
  const [boxsup2, setBoxSup2] = useState(false)
  const [boxsup3, setBoxSup3] = useState(false)
  const xIcon = <FontAwesomeIcon icon={faX} className='fa-fw' />


  useEffect(() => {
    if(data){
        setJobItem(data)
        if (data.gp_supplier_other_one != null) {
          setBoxSup1(true)
        }
        if (data.gp_supplier_other_sec != null) {
          setBoxSup2(true)
        }
        if (data.gp_supplier_other_thr != null) {
          setBoxSup3(true)
        }
        getSup()
    }
  }, [data])

  useEffect(() => {
    setAttachment(jobitem)
  }, [jobitem,setAttachment])

  const clearRow = (id: number) => {
    try {
      if (id === 1) {
        setJobItem((jobitem: any) => ({
          ...jobitem,
          gp_supplier_choose_id_one: null,
          gp_supplier_other_one: null,
          gp_supplier_choose_file_one: null,
        }))
        setBoxSup1(false)
      } else if (id === 2) {
        setJobItem((jobitem: any) => ({
          ...jobitem,
          gp_supplier_choose_id_sec: null,
          gp_supplier_other_sec: null,
          gp_supplier_choose_file_sec: null,
        }))
        setBoxSup2(false)
      } else if (id === 3) {
        setJobItem((jobitem: any) => ({
          ...jobitem,
          gp_supplier_choose_id_thr: null,
          gp_supplier_other_thr: null,
          gp_supplier_choose_file_thr: null,
        }))
        setBoxSup3(false)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const getSup = async () => {
    let options: any = []
    const res: any = await SUPOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => options.push({value: val.id, label: val.title}))
      setSup(options)
    }
  }

  const getNewDataFile = (type: string, name: string) => {
    if (jobitem) {
      if (type === 'sup_file_1') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_file_one: name}))
      } else if (type === 'sup_file_2') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_file_sec: name}))
      } else if (type === 'sup_file_3') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_file_thr: name}))
      }
    }
  }

  const getNewData = (type: string, id: any) => {
    if (jobitem) {
      if (type === 'sup_1') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_id_one: id}))
      } else if (type === 'sup_2') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_id_sec: id}))
      } else if (type === 'sup_3') {
        setJobItem((jobitem) => ({...jobitem, gp_supplier_choose_id_thr: id}))
      }
    }
  }

  const dialogFile = (type: string) => {
    let box = document.getElementById(`bg_load`) as HTMLInputElement
    let input = document.createElement('input')
    input.type = 'file'
    box.style.display = 'block'
    input.onchange = async (e: any) => {
      let file = e.target.files[0]
      let size: number = file.size || 0
      box.style.display = 'none'
      if (size <= 2000000) {
        Swal.fire({
          title: 'Are you confirm to upload?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          showLoaderOnConfirm: true,
          heightAuto: false,
          returnFocus: false,
          preConfirm: (e) => {
            return new Promise(async function (resolve) {
              let form = new FormData()
              form.append('file', file, file.name)
              await UploadSUP(form)
                .then((res) => {
                  if (res.data.status === true) {
                    Swal.fire({
                      title: 'Successful upload!',
                      icon: 'success',
                      showConfirmButton: false,
                      heightAuto: false,
                      timer: 1500,
                      returnFocus: false,
                    })
                    getNewDataFile(type, res.data.message)
                  }
                })
                .catch((err: any) => {
                  Swal.fire({
                    title: 'Failed to save',
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                })
            })
          },
        })
      } else {
        Swal.fire({
          title: 'File size not more than 2 MB.',
          icon: 'error',
          heightAuto: false,
          returnFocus: false,
        })
      }
    }
    input.click()
  }

  return (
    <>
      {/* quatation attachement */}
      <div className='col-md-6'>
        <div className='d-flex justify-content-between mb-2'>
          <h5>Quotation Attachment</h5>
          <AddSupplier getSup={getSup} />
        </div>
        <div className='table-responsive'>
          <table className='table table-sm text-center'>
            <thead className='border-bottom border-gray-200 text-gray-600 fw-bold bg-light bg-opacity-75'>
              <tr>
                <th colSpan={1} className='min-w-200px'>
                  Supplier name
                </th>
                <th colSpan={1} className='min-w-50px'>
                  Manual
                </th>
                <th colSpan={1} className='min-w-50px'>
                  Attach File
                </th>
                <th colSpan={1} className='min-w-40px'>
                  View
                </th>
                <th colSpan={1} className='min-w-25px'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='align-middle'>
                  {boxsup1 ? (
                    <input
                      type='text'
                      className='form-control form-control-sm border border-danger'
                      placeholder='Please fill out'
                      maxLength={255}
                      value={jobitem.gp_supplier_other_one}
                      onChange={(e) => {
                        setJobItem((jobitem: any) => ({
                          ...jobitem,
                          gp_supplier_other_one: e.target.value,
                        }))
                      }}
                    />
                  ) : (
                    <Select
                      className='text-start'
                      menuPortalTarget={document.body}
                      options={sup}
                      styles={customStyles}
                      value={
                        sup?.length > 0 && jobitem?.gp_supplier_choose_id_one
                          ? sup?.find(
                              (option: any) => jobitem?.gp_supplier_choose_id_one === option?.value
                            )
                          : null
                      }
                      onChange={(option: any) => {
                        if (option && option.value) {
                          getNewData('sup_1', option.value)
                          setJobItem((jobitem: any) => ({
                            ...jobitem,
                            gp_supplier_choose_id_one: option.value,
                          }))
                        } else {
                          getNewData('sup_1', null)
                        }
                      }}
                      isClearable
                    />
                  )}
                </td>
                <td className='align-middle'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    checked={boxsup1}
                    onChange={(e) => {
                      setBoxSup1(e.target.checked)
                      setJobItem((jobitem: any) => ({
                        ...jobitem,
                        gp_supplier_choose_id_one: null,
                        gp_supplier_other_one: null,
                      }))
                    }}
                  />
                </td>
                <td className='align-middle'>
                  <button
                    className='btn btn-secondary btn-sm text-primary'
                    onClick={() => {
                      dialogFile('sup_file_1')
                    }}
                    onFocusCapture={BGClose}
                  >
                    Upload
                  </button>
                </td>
                <td className='align-middle'>
                  {jobitem.gp_supplier_choose_file_one !== ''
                    ? OpenFile(jobitem.gp_supplier_choose_file_one, 'suppliers')
                    : '-'}
                </td>
                <td className='align-middle'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-danger'
                    title='clear row'
                    onClick={() => {
                      clearRow(1)
                    }}
                  >
                    {xIcon}
                  </button>
                </td>
              </tr>
              <tr>
                <td className='align-middle'>
                  {boxsup2 ? (
                    <input
                      type='text'
                      className='form-control form-control-sm border border-danger'
                      placeholder='Please fill out'
                      maxLength={255}
                      value={jobitem.gp_supplier_other_sec}
                      onChange={(e) => {
                        setJobItem((jobitem: any) => ({
                          ...jobitem,
                          gp_supplier_other_sec: e.target.value,
                        }))
                      }}
                    />
                  ) : (
                    <Select
                      className='text-start'
                      menuPortalTarget={document.body}
                      options={sup}
                      styles={customStyles}
                      value={
                        sup?.length > 0 && jobitem?.gp_supplier_choose_id_sec
                          ? sup.find(
                              (option: any) => jobitem?.gp_supplier_choose_id_sec === option?.value
                            )
                          : null
                      }
                      onChange={(option: any) => {
                        if (option && option.value) {
                        setJobItem((jobitem: any) => ({
                            ...jobitem,
                            gp_supplier_choose_id_sec: option.value,
                            }))
                          getNewData('sup_2', option.value)
                        } else {
                          getNewData('sup_2', null)
                        }
                      }}
                      isClearable
                    />
                  )}
                </td>
                <td className='align-middle'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    checked={boxsup2}
                    onChange={(e) => {
                      setBoxSup2(e.target.checked)
                      setJobItem((jobitem: any) => ({
                        ...jobitem,
                        gp_supplier_choose_id_sec: null,
                        gp_supplier_other_sec: null,
                      }))
                    }}
                  />
                </td>
                <td className='align-middle'>
                  <button
                    className='btn btn-secondary btn-sm text-primary'
                    onClick={() => {
                      dialogFile('sup_file_2')
                    }}
                    onFocusCapture={BGClose}
                  >
                    Upload
                  </button>
                </td>
                <td className='align-middle'>
                  {jobitem.gp_supplier_choose_file_sec !== ''
                    ? OpenFile(jobitem.gp_supplier_choose_file_sec, 'suppliers')
                    : '-'}
                </td>
                <td className='align-middle'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-danger'
                    title='clear row'
                    onClick={() => {
                      clearRow(2)
                    }}
                  >
                    {xIcon}
                  </button>
                </td>
              </tr>
              <tr>
                <td className='align-middle'>
                  {boxsup3 ? (
                    <input
                      type='text'
                      className='form-control form-control-sm border border-danger'
                      placeholder='Please fill out'
                      maxLength={255}
                      value={jobitem.gp_supplier_other_thr}
                      onChange={(e) => {
                        setJobItem((jobitem: any) => ({
                          ...jobitem,
                          gp_supplier_other_thr: e.target.value,
                        }))
                      }}
                    />
                  ) : (
                    <Select
                      className='text-start'
                      menuPortalTarget={document.body}
                      options={sup}
                      styles={customStyles}
                      value={
                        sup?.length > 0 && jobitem?.gp_supplier_choose_id_thr
                          ? sup.find(
                              (option: any) => jobitem?.gp_supplier_choose_id_thr === option?.value
                            )
                          : null
                      }
                      onChange={(option: any) => {
                        if (option && option.value) {
                        setJobItem((jobitem: any) => ({
                            ...jobitem,
                            gp_supplier_choose_id_thr: option.value,
                            }))
                          getNewData('sup_3', option.value)
                        } else {
                          getNewData('sup_3', null)
                        }
                      }}
                      isClearable
                    />
                  )}
                </td>
                <td className='align-middle'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    checked={boxsup3}
                    onChange={(e) => {
                      setBoxSup3(e.target.checked)
                      setJobItem((jobitem: any) => ({
                        ...jobitem,
                        gp_supplier_choose_id_thr: null,
                        gp_supplier_other_thr: null,
                      }))
                    }}
                  />
                </td>
                <td className='align-middle'>
                  <button
                    className='btn btn-secondary btn-sm text-primary'
                    onClick={() => {
                      dialogFile('sup_file_3')
                    }}
                    onFocusCapture={BGClose}
                  >
                    Upload
                  </button>
                </td>
                <td className='align-middle'>
                  {jobitem.gp_supplier_choose_file_thr !== ''
                    ? OpenFile(jobitem.gp_supplier_choose_file_thr, 'suppliers')
                    : '-'}
                </td>
                <td className='align-middle'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-danger'
                    title='clear row'
                    onClick={() => {
                      clearRow(3)
                    }}
                  >
                    {xIcon}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* !!quatation attachement */}
    </>
  )
}

export default QuotationAttachment
