const UrgentFormat = (urgent: number) => {
    if(urgent == 1){
        // return <span className='badge badge-light-warning'>urgent</span>\
        return 'text-danger'
    }else{
        return 'text-danger'
        // return <span className='badge badge-light-success'>nomal</span>
    }
}

const UrgentFormat2 = (urgent: number) => {
    if(urgent == 1){
        return <span className='badge badge-light-warning'>urgent</span>
    }else{
        return <span className='badge badge-light-success'>nomal</span>
    }
}

export {UrgentFormat, UrgentFormat2}