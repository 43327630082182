import {useState, useRef} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faCalendarDays,
} from '@fortawesome/free-solid-svg-icons'
import {CoverTime3, CoverTime4, CovertTime} from '../../modules/widgets/func/CoverTime'
import {UrgentFormat2} from '../../modules/widgets/quotation/func/UrgentFormat'
import {statusFormat} from '../../modules/widgets/quotation/func/QSStatus'
import {OpenFile} from '../../modules/widgets/func/OpenFile'
import {ReadMore} from '../../modules/widgets/func/ReadMore'
import {QuotationGroupList} from '../../modules/api/requests/QuotationRequest'
import {RemarkHistories} from '../../modules/api/requests/RemarkRequest'
import clsx from 'clsx'
import {UndoQuotationList} from '../../modules/api/requests/UndoRequest'

interface QSShowProps {
  data: any
  className?: string
}

const QSShow = ({data, className}: QSShowProps) => {
  const [show, setShow] = useState(false)
  const [fullscreen, setFullscreen] = useState<any>('')
  const [jobs, setJobs] = useState([])

  const upIcon = <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className='fa-fw' />
  const downIcon = <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className='fa-fw' />
  const calendar = <FontAwesomeIcon icon={faCalendarDays} className='fa-fw text-primary' />

  const formButtonRef: any = useRef(null)

  const handleClose = () => {
    setShow(false)
    setQuotationHistories([])
    setRemark([])
  }
  const handleShow = () => {
    formButtonRef.current.blur()
    getJobs()
    getRemark()
    getQuotationHistories()
    setShow(true)
  }

  const openFull = () => {
    if (fullscreen === true) {
      setFullscreen('')
    } else {
      setFullscreen(true)
    }
  }

  const getJobs = async () => {
    const job_id: number = data.job_no
    const res: any = await QuotationGroupList(job_id)
    if (res.data.data) {
      setJobs(res.data.data)
    }
  }

  // --- remark
  const [remarks, setRemark] = useState([])

  const getRemark = async () => {
    try {
      const res: any = await RemarkHistories(data.id)
      if (res.data.data.length > 0) {
        setRemark(res.data.data)
      } else {
        setRemark([])
      }
    } catch (err) {
      console.error(err)
    }
  }

  // --- quotation histories
  const [quotationHistories, setQuotationHistories] = useState([])

  const getQuotationHistories = async () => {
    try {
      const res: any = await UndoQuotationList(data.id)
      if (res.data.data.length > 0) {
        setQuotationHistories(res.data.data)
      } else {
        setQuotationHistories([])
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <button
        ref={formButtonRef}
        className={clsx('btn btn-icon btn-light-info  btn-sm me-2', className)}
        onClick={() => {
          handleShow()
        }}
      >
        <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' />
      </button>
      <Modal show={show} onHide={handleClose} size='xl' fullscreen={fullscreen} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Quotation</h2>
          </Modal.Title>
          <button
            className='btn btn-light-white btn-sm'
            onClick={() => {
              openFull()
            }}
          >
            {fullscreen != true ? downIcon : upIcon}
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-12 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Requestor
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.requestor_name || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-2 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  GP no
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.job_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-2 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Job User no
                </label>
                <input
                  type='text'
                  className='form-control  form-control-sm form-control-solid'
                  value={data.job_user_no || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Section/Department
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.dept_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  For dept
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.for_dept_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Group from System
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.ig_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Item
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.item_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Item Other
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.item_other || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-3 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Item Description
                </label>
                <textarea
                  rows={1}
                  className='form-control form-control-sm form-control-solid'
                  value={data.item_description || '-'}
                  readOnly
                ></textarea>
              </div>
            </div>
            <div className='col-md-2 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  QTY
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.qty || '0'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-2 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Unit
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.unit_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Supplier
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.supplier_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Supplier Other
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.supplier_other || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Branch
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.branch_title || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  GP Verify
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.pic || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  GP Verify Comment:
                </label>
                <textarea
                  rows={1}
                  className='form-control form-control-sm form-control-solid'
                  value={data.gp_verify_comment || '-'}
                  readOnly
                ></textarea>
              </div>
            </div>
            <div className='col-md-2 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Urgency
                </label>
                <div className='mt-2'>{UrgentFormat2(data.urgency)}</div>
              </div>
            </div>
            <div className='col-md-2 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Status
                </label>
                <div className='mt-2'>{statusFormat(data.status, data)}</div>
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  {calendar} Requesition Date
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={CovertTime(data.created_at) || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  {calendar} Require Date
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={CovertTime(data.require_date) || '-'}
                  readOnly
                />
              </div>
            </div>
            {data.finished_date && (
              <div className='col-md-4 mb-2'>
                <div className='form-group'>
                  <label htmlFor='' className='fw-bold fs-6 mb-2'>
                    {calendar} Finished Date
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-sm form-control-solid'
                    value={CovertTime(data.finished_date) || '-'}
                    readOnly
                  />
                </div>
              </div>
            )}
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Remark by GP
                </label>
                <input
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  value={data.remark_user_name || '-'}
                  readOnly
                />
              </div>
            </div>
            <div className='col-md-4 mb-2'>
              <div className='form-group'>
                <label htmlFor='' className='fw-bold fs-6 mb-2'>
                  Remark
                </label>
                <textarea
                  rows={1}
                  className='form-control form-control-sm form-control-solid'
                  value={data.remark_content || '-'}
                  readOnly
                ></textarea>
              </div>
            </div>
            <div className='col-md-12 mb-2 mt-2'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='mb-2'>
                    <h5>User Attachment</h5>
                    <div className='table-responsive'>
                      <table className='table table-sm table-bordered-2 text-center'>
                        <thead className='bg-primary'>
                          <tr>
                            <th className='text-white min-w-100px fw-bold align-middle'>
                              Picture(FA)
                            </th>
                            <th className='text-white min-w-100px fw-bold align-middle'>
                              Drawing(FA)
                            </th>
                            <th className='text-white min-w-100px fw-bold align-middle'>
                              Quotation by User
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='align-middle p-1'>
                              {OpenFile(data.file_url_pic, 'pic')}
                            </td>
                            <td className='align-middle p-1'>
                              {OpenFile(data.file_url_draw, 'draw')}
                            </td>
                            <td className='align-middle p-1'>
                              {OpenFile(data.file_url_qto, 'quotation')}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-2'>
                    <h5>Supplier Attachment</h5>
                    <div className='table-responsive'>
                      <table className='table table-sm table-bordered-2 text-center'>
                        <thead className='bg-primary'>
                          <tr>
                            <th className='text-white min-w-100px fw-bold align-middle'>
                              Supplier Name
                            </th>
                            <th className='text-white min-w-100px fw-bold align-middle'>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='align-middle p-1'>
                              {data.gp_supplier_one || data.gp_supplier_other_one || '-'}
                            </td>
                            <td className='align-middle p-1'>
                              {OpenFile(data.gp_supplier_choose_file_one, 'suppliers')}
                            </td>
                          </tr>
                          <tr>
                            <td className='align-middle p-1'>
                              {data.gp_supplier_sec || data.gp_supplier_other_sec || '-'}
                            </td>
                            <td className='align-middle p-1'>
                              {OpenFile(data.gp_supplier_choose_file_sec, 'suppliers')}
                            </td>
                          </tr>
                          <tr>
                            <td className='align-middle p-1'>
                              {data.gp_supplier_thr || data.gp_supplier_other_thr || '-'}
                            </td>
                            <td className='align-middle p-1'>
                              {OpenFile(data.gp_supplier_choose_file_thr, 'suppliers')}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='mb-2'>
                    <h5>Quotation Group List</h5>
                    <div
                      className='table-responsive'
                      style={jobs.length > 0 ? {maxHeight: '245px'} : {}}
                    >
                      <table className='table table-sm table-bordered-2 text-center'>
                        <thead className='bg-primary text-white'>
                          <tr>
                            <th className='fw-bold align-middle'>GP No.</th>
                            <th className='fw-bold align-middle'>Job User no.</th>
                            <th className='fw-bold align-middle'>Group</th>
                            <th className='fw-bold align-middle'>Item</th>
                            <th className='fw-bold align-middle'>Picture(FA)</th>
                            <th className='fw-bold align-middle'>Drawing(FA)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobs.length > 0 ? (
                            jobs.map((val: any) => {
                              return (
                                <tr>
                                  <td className='align-middle p-1'>{val.job_number || '-'}</td>
                                  <td className='align-middle p-1'>{val.job_user_no || '-'}</td>
                                  <td className='align-middle p-1'>
                                    {val.item_group_title || '-'}
                                  </td>
                                  <td className='align-middle p-1'>
                                    {val.item_title || val.item_other || '-'}
                                  </td>
                                  <td className='align-middle p-1'>
                                    {OpenFile(val.file_url_pic, 'pic')}
                                  </td>
                                  <td className='align-middle p-1'>
                                    {OpenFile(val.file_url_draw, 'draw')}
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={6}>No matching records found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12'>
                  {remarks?.length > 0 && (
                    <div className='mb-2'>
                      <h5>Remark History</h5>
                      <div
                        className='table-responsive'
                        style={remarks.length > 0 ? {maxHeight: '245px'} : {}}
                      >
                        <table className='table table-sm table-bordered-2 text-center'>
                          <thead className='bg-primary text-white'>
                            <tr>
                              <th className='fw-bold align-middle'>Content</th>
                              <th className='fw-bold align-middle'>User Remark</th>
                              <th className='fw-bold align-middle'>Create Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {remarks?.map((item: any, index: number) => (
                              <tr key={index}>
                                <td>{item?.content}</td>
                                <td>{item?.firstnameEn + ' ' + item?.lastnameEn}</td>
                                <td>{CoverTime4(item.created_at)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-lg-12'>
                  {quotationHistories?.length > 0 && (
                    <div className='mb-2'>
                      <h5>Updated History</h5>
                      <div
                        className='table-responsive'
                        style={remarks.length > 0 ? {maxHeight: '245px'} : {}}
                      >
                        <table className='table table-sm table-bordered-2 text-center'>
                          <thead className='bg-primary text-white'>
                            <tr>
                              <th className='fw-bold align-middle'>Status</th>
                              <th className='fw-bold align-middle'>Update By</th>
                              <th className='fw-bold align-middle'>Update Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quotationHistories?.map((item: any, index: number) => (
                              <tr key={index}>
                                <td>{item?.status}</td>
                                <td>{item?.firstnameEn + ' ' + item?.lastnameEn}</td>
                                <td>{CoverTime4(item.created_at)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {QSShow}
